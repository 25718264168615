.MainBox {
  width: 480px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  padding-left: 3%;
  padding-top: 2%;
}
.header {
  display: flex;
  justify-content: space-between;
}
.header p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}
.header img {
  position: absolute;
  right: 0;
  width: 28px;
  height: 28px;
  margin-right: 4%;
  cursor: pointer;
}
.devider {
  width: 100%;
  height: 0.5px;
  background: #ddd;
  opacity: 0.5;
}
.alertBodyInner {
  padding: 25px 0px 20px 0px;
}
.alertLabel {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  width: 400px;
  margin: auto;
}
.alertLabel p {
  margin-bottom: 50px;
}
.buttonContainer {
  display: flex;
  width: 400px;
  margin: auto;
  height: 50px;
}
.buttonNo {
  width: 190px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.5px solid #6c757d;
  background: #6c757d;
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.buttonYes {
  width: 190px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.5px solid #0f7dff;
  background: #0f7dff;

  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

@media (max-width: 575px) {
  .MainBox {
    width: 100% !important;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
    padding: 10px;
  }

  .header p {
    color: #000;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .alertLabel {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 180% */
    width: 100%;
    margin: auto;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    margin: 0;
    height: auto;
    justify-content: center;
  }

  .buttonNo {
    border: 0.5px solid #6c757d;
    color: #fff;

    width: 120px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .buttonYes {
    border: 0.5px solid #3270a0;
    color: #fff;

    width: 120px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-left: 20px;
  }
}
@media (max-width: 425px) {
  .alertLabel {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    width: 100%;
    margin: auto;
  }
}
.alertCross svg {
  font-size: 30px;
  cursor: pointer;
  fill: #6c757d;
}

@media (max-width: 320px) {
  .buttonNo {
    font-size: 16px;
  }
  .buttonYes {
    font-size: 16px;
  }
}
