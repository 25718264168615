.vanTabAddVanButtion {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-bottom: 15px;
  padding: 10px 10px 10px 0px;
}
.vanTabAddVanButtion button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 27px;
  border: 0.5px solid #0f7dff54;
  border-radius: 4px;
  background-color: #0f7dff;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.couponHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.couponHeading h1 {
  font-family: Inter;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #333333;
}

.AddVanBtn button {
  width: 100%;
  padding: 12px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
}

.minTimeInput {
  background-color: #ffffff !important;
  width: 38px;
  height: 38px;
  top: 6%;
  position: absolute;
  right: 1%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default !important;
}
.minTimeInput svg {
  width: 22px !important;
  height: 22px !important;
  color: #333333 !important;
}

.date {
  color: #333333 !important;
  position: absolute;
  right: 13px;
  top: 10px;
  cursor: default !important;
  width: 24px !important;
  height: 24px !important;
  z-index: 1;
}
.dateRageTO {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.dateRageTO span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateRageTO span svg {
  fill: #828282 !important;
  height: 25px;
}

.dateRageTO span p {
  margin-bottom: 0px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
}

.dateRageInputSvg {
  color: #828282 !important;
  position: absolute;
  right: 13px;
  top: 10px;
  cursor: default !important;
  width: 24px !important;
  height: 24px !important;
  z-index: 1;
}

.visibleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

@media (max-width: 991) {
  .couponHeading h1 {
    font-size: 21px;
  }
}

@media (max-width: 768) {
  .couponHeading h1 {
    font-size: 18px;
  }
}
