.furnitureMainSection {
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 2px 0px #00000026;
  background-color: #fcfcfc;
  padding: 15px 15px 0px 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.furnitureMainTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.furnitureMainTop h1 {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.furnitureMainTop button {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background: #0f7dff;
  border-radius: 4px;
  border: none;
  padding: 6px 18px;
  margin-bottom: 20px;
}
.furnitureDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 10px;
  border-radius: 4px;
  position: relative;
  border: 1px solid #dbdbdb;
  margin-bottom: 20px;
}
.furnitureDiv span svg path {
  fill: #333333;
}
.furnitureDiv:hover {
  cursor: pointer;
  border: 1px solid #0f7dff;
  transition: 0.3s ease-out;
  color: #0094f6;
}
.furnitureDiv {
  padding: 10px;
  margin: 5px;
  margin-top: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.furnitureDiv p:hover {
  color: #0094f6;
  transition: 0.3s ease-out;
}
.furnitureDiv:hover span svg path {
  fill: #0094f6;
  transition: 0.3s ease-out;
}
.furnitureDiv span svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.furnitureDiv p {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0px;
}
.furnitureDiv svg {
  color: red;
  font-size: 25px;
  font-weight: bold;
  background: white;
  position: absolute;
  top: -14px;
  right: -7px;
}
.furnitureBottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 0.3px solid #555555;
  box-shadow: 0px 1px 2px 0px #00000026;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 15px;
  position: relative;
  margin-top: 5px;
}
.furnitureBottom p {
  margin-bottom: 0px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  word-break: break-all;
  margin-right: 10px;
}
.furnitureBottom span svg {
  width: 19px !important;
  height: 19px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  color: #202020 !important;
  position: unset !important;
}
.furnitureBottom svg {
  color: red;
  font-size: 25px;
  font-weight: bold;
  background: white;
  position: absolute;
  top: -14px;
  right: -7px;
  cursor: pointer;
}
.furnitureUpdate {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.furnitureUpdate button {
  background: #0f7dff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  padding: 10px 80px;
  border-radius: 4px;
}
.AddVanBtn button {
  width: 100%;
  padding: 10px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border: none;
}

.addVanUploadBtn {
  width: 100%;
}
.addVanUploadBtn h1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #0094f6;
  margin-bottom: 12px;
  text-align: center;
}
.AddSubUploadDiv {
  margin-bottom: 30px;
}
.CategoryDevider {
  background: #333333;
  width: 1px;
  height: 25px;
  position: absolute;
  right: 56px;
  top: 12px;
  z-index: 999;
}
.SelectDuration {
  position: absolute;
  width: 45px;
  height: 41px;
  border: 0px;
  right: 4px;
  top: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  padding: 0px 7px;
}

.SelectDurationInner p {
  margin-bottom: 0px;
}

.furnitureDiv img {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}

.uploadSection {
  margin: 20px 0px;
}

.uploadSectionSpace {
  margin: 0px 0px !important;
}

.uploadSection h4 {
  color: #0094f6;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
}

.uploadImage {
  border: 1px dotted #6cadd3;
  background: #fafafa;
  width: 100%;
  max-width: 315px;
  height: 200px;
  margin: 0px auto;
  position: relative;
}

.cancelIcon svg {
  color: #0f7dff;
  width: 27px;
  height: 34px;
  position: absolute;
  right: -8px;
  top: -17px;
  cursor: pointer;
  z-index: 9999 !important;
}

.uploadInputImg {
  width: 100%;
  text-align: center;
}

.uploadInputImg img {
  border: none;
  object-fit: contain;
  position: relative;
  width: 100%;
  max-width: 315px;
  height: 200px;
  padding: 14px;
}

.uploadInput {
  text-align: center;
  top: 46px;
  position: absolute;
  left: 0;
  right: 0;
}

.uploadInput p {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #777777;
  text-align: center;
}

.uploadbutton {
  background: linear-gradient(303.02deg, #2fc2fe 29.51%, #0094f6 81.02%);
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px 30px;
}

.uploadbutton svg {
  font-size: 17px !important;
  color: #ffffff !important;
  margin-right: 3px !important;
  margin-top: -4px;
}
.itemScroll {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 7px 11px;
}
.itemScroll::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}
.itemScroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}
.itemScroll::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.itemScrollSpace {
  padding: 7px 11px;
}

.minTimeInput {
  background-color: #ffffff !important;
  width: 66px;
  height: 46px;
  top: 2%;
  position: absolute;
  right: 1%;
  z-index: 9999;
}

@media (max-width: 991px) {
  .furnitureMainTop h1 {
    font-size: 16px;
  }
  .furnitureMainTop button {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .furnitureDiv img {
    width: 27px;
    height: 27px;
  }
}

@media (max-width: 425px) {
  .furnitureUpdate button {
    width: 100%;
    padding: 10px 0px;
  }
  .furnitureMainTop h1 {
    font-size: 14px !important;
  }

  .furnitureMainTop button {
    padding: 6px 8px;
  }
}

@media (max-width: 360px) {
  .furnitureMainSection {
    padding: 15px 7px 0px 7px;
  }
}
