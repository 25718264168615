.dashboardCard {
  width: 100%;
}

.dashboardCardInner {
  width: 100%;
  border-radius: 15px;
  background: #2fc2fe;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 20px 0px;
  margin-bottom: 30px;
}

.dashboardCardInner span {
  margin-bottom: 18px;
}
.dashboardCardInner span svg {
  width: 58px;
  height: 58px;
}

.dashboardCardInner h1 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 18px;
}

.dashboardCardInner p {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
}

.tableDate {
  color: #000000 !important;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  vertical-align: top;
}

.tableCategory {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #202020;
  vertical-align: top;
}

.tableAmount {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
  vertical-align: top;
}

.tableStatus {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  vertical-align: top;
  vertical-align: top;
}

/* ------ */
.dote {
  text-align: start;
}
.dote ul {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 3px;
  padding: 0px;
  cursor: pointer;
  margin-bottom: 0px;
}
.dote ul li {
  list-style: none;
  width: 3.11px;
  height: 3.11px;
  border-radius: 100%;
  background-color: #333333;
}

@media (max-width: 768px) {
  .dashboardCardInner span {
    margin-bottom: 15px;
  }
  .dashboardCardInner h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .dashboardCardInner p {
    font-size: 14px;
  }
  .tablePaginationDash {
    color: red;
  }
}
@media (max-width: 425px) {
  .dashboardCardInner span svg {
    width: 50px;
    height: 50px;
  }
  .dashboardCardInner span {
    margin-bottom: 10px;
  }
  .dashboardCardInner h1 {
    font-size: 21px;
    margin-bottom: 10px;
  }

  .dashboardCardInner p {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .dashboardCardInner {
    margin-bottom: 15px;
  }
}

.currancySymbol{
  font-family: Inter;
  font-size: 13.3px;
  font-weight: 600;
  color: #202020;
}

