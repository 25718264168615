.commissionMainSection {
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 6px;
  padding: 30px 25px;
  min-width: 500px;
}

.commissionMainSection h5 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 60px;
}

.commissionMainSection h5::after {
  content: "";
  display: block;
  width: 50px;
  margin-top: 4px;
  padding-top: 1px;
  border-radius: 20px;
  border-bottom: 2px solid transparent;
  background: #0f7dff;
}
.AddVanBtn button {
  width: 100%;
  padding: 10px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  margin-top: 250px;
}

.minTimeInput {
  background-color: #ffffff !important;
  width: 38px;
  height: 38px;
  top: 13% !important;
  position: absolute;
  right: 2%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default !important;
}
.minTimeInput svg {
  width: 22px !important;
  height: 22px !important;
  color: #333333 !important;
}

.serviceChargeBtn button {
  width: 100%;
  padding: 10px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  margin-top: 70px;
}
.checkBoxSwitch input {
  width: 51px !important;
  height: 25px;
  border: 2px solid gray;
  cursor: pointer;
}

.checkBoxSwitch input:focus {
  box-shadow: unset !important;
}

@media (max-width: 991px) {
  .commissionMainSection {
    min-width: 100%;
  }
}
