.userDetailsDate {
  height: 45px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  background-size: auto;
  border-radius: 4px;
  color: #757575;
  background-repeat: no-repeat;
  background-position: 90%;
  border: 0.8px solid #d0d0d0;
  z-index: 1;
}
.tableAmount {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
  vertical-align: top;
}
.tableDate {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #000000;
  vertical-align: top;
}
.tableDr {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  color: #202020;
  vertical-align: top;
}

.paymentStatus {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  vertical-align: top;
}
.tableStatu {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  vertical-align: top;
}
.dote {
  text-align: start;
}
.dote ul {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 3px;
  padding: 0px;
  cursor: pointer;
  margin-bottom: 0px;
}
.dote ul li {
  list-style: none;
  width: 3.11px;
  height: 3.11px;
  border-radius: 100%;
  background-color: #333333;
}
.currancySymbol {
  font-family: Inter;
  font-size: 13.3px;
  font-weight: 600;
  color: #202020;
}
.selectDateRange {
  display: grid;
}
.selectDateRange input {
  height: 45px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  background-size: auto;
  border-radius: 4px;
  color: #757575;
}
