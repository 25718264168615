.livechatLeft {
  border: 0.5px solid #d9d9d9;
  border-radius: 6px;
  height: 100vh;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.livechatLeft::-webkit-scrollbar {
  width: 4px;
  border-radius: 50px;
}

/* Track */
.livechatLeft::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.livechatLeft::-webkit-scrollbar-thumb {
  background: #d6d6d6;
}

.livechatCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 10px 0px 10px;
  border-bottom: 0.6px solid #e3e3e3;
}
.livechatCardLeft {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}
.livechatCardLeft span {
  width: 47px;
  height: 47px;
  border-radius: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ececec;
  margin-bottom: 15px;
  position: relative;
}
.livechatCardLeft span img {
  width: 45px;
  height: 45px;
}

.livechatCardLeft span h4 {
  position: absolute;
  top: -14px;
  right: 4px;
}
.livechatCardLeft span h4 img {
  width: 6px;
  height: 6px;
  border: none;
}

.livechatLeftText {
  margin-bottom: 15px;
}

.livechatLeftText h5 {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 5px;
}
.livechatLeftText p {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  color: #acacac;
  margin-bottom: 0px;
}

.livechatCardRight {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 76px;
  margin-bottom: 15px;
}

.livechatCardRight p {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  color: #a3a3a3;
  margin-bottom: 0px;
  margin-right: 10px;
}

.livechatCardRight span {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #0094f6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px 0px #00000029;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
}

.livechatRightHeader {
  background: #0094f6;
  padding: 10px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.livechatRightHeaderInner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.livechatRightHeaderInner span img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  margin-right: 20px;
  border: 1px solid #ececec;
}
.livechatHeadeText h1 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 4px;
}
.livechatHeadeText p {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  color: #10cc39;
  margin-bottom: 0px;
}
/* ---- */
.sendMessageInput {
  z-index: 1;
  padding: 15px 15px;
}
.sendMessageInputInner {
  padding: 20px 15px;
  border-radius: 100px !important;
  height: 56px;
  border: 0.3px solid #e3e3e3;
  background-color: #f1f1f1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sendMessageSendBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sendMessageSendBox h5 {
  margin-bottom: 0px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.sendMessageSendBox span {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #0094f6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sendMessageSendBox span svg {
  width: 15px;
  height: 15px;
}

.allChatBox {
  overflow-y: scroll;
  padding: 60px 20px;
  height: 78vh;
}
.chatMessageLeft h2 {
  border: 0.3px solid #dadada;
  box-shadow: 0px 1px 2px 0px #00000017;
  border-top-left-radius: 10px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #f3f3f3;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #393939;
  padding: 15px 25px;
  width: fit-content;
  max-width: 50%;
}

.chatMessageLeft p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #8b8b8b;
}
.chatMessageRight {
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
}
.chatMessageRight h2 {
  border: 0.3px solid #dadada;
  box-shadow: 0px 1px 2px 0px #00000017;
  border-top-left-radius: 30px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 30px;
  background-color: #0094f6;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: white;
  padding: 15px 25px;
  width: fit-content;
  max-width: 50%;
}

.chatMessageRight p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #8b8b8b;
}

.allChatBox::-webkit-scrollbar {
  width: 4px;
  border-radius: 50px;
}

/* Track */
.allChatBox::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.allChatBox::-webkit-scrollbar-thumb {
  background: #d6d6d6;
}

@media (max-width: 768px) {
  .chatMessageLeft h2 {
    max-width: 75%;
  }
  .chatMessageRight h2 {
    max-width: 75%;
  }
}
@media (max-width: 425px) {
  .chatMessageLeft h2 {
    max-width: 95%;
    padding: 10px 15px;
  }
  .chatMessageRight h2 {
    max-width: 90%;
    padding: 10px 15px;
  }
  .livechatHeadeText h1 {
    font-size: 12px;
  }
}
