.b2bEnquiryTopTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.b2bEnquiryTopTitle h5 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333333;
}

@media (max-width: 991px) {
  .b2bEnquiryTopTitle h5 {
    font-size: 21px;
  }
  .vanTabAddVanButtion button {
    padding: 6px 21px;
  }
}
@media (max-width: 768px) {
  .b2bEnquiryTopTitle h5 {
    font-size: 18px;
  }
}

.replySubmitBtn button {
  width: 100%;
  height: 45px;
}
