.loginMain {
  width: 100%;
  padding: 101.5px 0px;
  height: 100vh;
  background-color: #0094f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBoxInner {
  height: 100%;
  box-shadow: 0px 3px 12px 0px rgba(255, 255, 255, 0.2);
}

.loginBoxTop {
  width: 425px;
  padding: 30px 20px;
  background-color: white;
  border-radius: 10px;
}

.loginBoxLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
}

.loginLogoIn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 159px;
  height: 159px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.04);
}

.loginLogoIn img {
  width: 157px;
  height: 157px;
  object-fit: contain;
}

.loginLable {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 9px;
}

.loginForm {
  width: 100%;
  margin-bottom: 14px;
}
.formGroup {
  margin-bottom: 14px;
  position: relative;
}

.formGroup input {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  height: 45px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  border: 1px solid #bfbfbf;
  background: #ffffff;
  display: block;
  width: 100%;
  padding: 12px 0.75rem;
  border-radius: 5px;
}
.formGroup input::placeholder {
  color: #7a7a7a;
}
.loginForm .forgetPasswordInput {
  padding: 10px 42px 10px 10px;
  margin-bottom: 0px;
}

.formGroup svg {
  position: absolute;
  top: 45px;
  right: 13px;
  font-size: 22px;
  cursor: pointer;
  color: rgba(130, 130, 130, 0.85);
}

.forgetPassword {
  margin-bottom: 58px;
  text-align: end;
}
.forgetPassword a {
  text-decoration: none;
  color: #1b1837;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.loginButton button {
  width: 100%;
  background-color: #0f7dff;
  text-align: center;
  padding: 12px 0px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}

@media (min-width: 100px) and (max-width: 475px) {
  .loginBoxTop {
    width: 100%;
    width: 86vw;
  }
}

@media (max-width: 425px) {
  .loginLogoIn {
    width: 140px;
    height: 140px;
  }

  .loginLogoIn img {
    width: 98px;
    height: 95px;
  }
}
