body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scrollbar-width: none; */
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 50px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 50px;
}
