.MetaDatVanTableInner {
  margin-bottom: 0px;
  max-height: 70vh;
  overflow-y: auto;
}

.MetaDatVanTableInner::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

.MetaDatVanTableInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

.MetaDatVanTableInner::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.MetaDatVanTableInner_floor {
  margin-bottom: 30px;
  max-height: 75vh;
  overflow-y: auto;
}

.MetaDatVanTableInner_floor::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

.MetaDatVanTableInner_floor::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

.MetaDatVanTableInner_floor::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.vanTabAddVanButtion {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-bottom: 15px;
  padding: 10px 10px;
}
.vanTabAddVanButtion button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 27px;
  border: 0.5px solid #0f7dff54;
  border-radius: 4px;
  background-color: #0f7dff;
  gap: 10px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}

.uploadBtnMain {
  position: absolute;
  bottom: 10px;
  left: 100px;
  text-align: center;
  z-index: 1;
}

/* ---floor--- */
.floorTable {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
}

.floorTable button {
  border: 0.5px solid #0f7dff54;
  border-radius: 4px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #0f7dff;
  padding: 6px 18px;
  background: #0f7dff21;
}
/* helper */

.helperMainSection {
  border: 0.3px solid #c0c0c0;
  box-shadow: 0px 0px 4px 0px #00000026;
  border-radius: 10px;
}
.helperMainTable {
  margin-bottom: 75px;
}

.helperTop {
  border-bottom: 0.3px solid #d3d3d3;
}

.helperTop h1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  padding: 15px 15px;
  margin-bottom: 0px;
}

.helperTableRowt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 15px;
  border: 0.3px solid #d3d3d3;
  border-top: none;
}

.helperTableRowt h2 {
  font-family: Inter;
  font-size: 15px;
  padding: 15px 15px;
  font-weight: 500;
  color: #202020;
  margin-bottom: 0px;
}

.helperTableRowt button {
  border: 0.5px solid #0f7dff54;
  border-radius: 4px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #0f7dff;
  padding: 6px 18px;
  background: #0f7dff21;
}

.AddVanBtn button {
  width: 100%;
  padding: 10px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
}

.addVanUploadBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}
.addVanUploadBtn h1 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: #0094f6;
  margin-bottom: 20px;
}
.vanUploadBtnInner {
  border: 1px dotted #6cadd3;
  border-radius: 4px;
  width: 70%;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 6px;
  position: relative;
}

.tutorialPopupMain .vanUploadBtnInner {
  margin-bottom: 15px;
}

.vanUploadImgIcon {
  text-align: center;
}

.vanUploadImgIcon img {
  width: 55px;
  height: 55px;
  margin-bottom: 14px;
}
.vanUploadBtnInner p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #777777;
  margin-bottom: 14px;
}
.vanUploadBtnInner button {
  background: linear-gradient(303.02deg, #2fc2fe 29.51%, #0094f6 81.02%);
  border-radius: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  padding: 6px 20px;
  cursor: pointer;
}
.vanUploadBtnInner button img {
  width: 18px;
  height: 18px;
  margin-right: 7px;
  cursor: pointer !important;
}

/* ----color--- */
.colorPopupMian {
  display: flex;
  justify-content: center;
}
.colorPopupInner {
  width: 70%;
}

.SelectedColorPreview {
  border-radius: 12px;
  height: 60px;
  margin-bottom: 30px;
  border: 1px solid #ece6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.selectedColorBox {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.theredotHorizontal {
  font-size: 20px;
  color: black;
}

.SketchPicker {
  width: 100% !important;
  padding: 10px !important;
}

.Minimum_time {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
}

/* ----van- */
.CategoryDevider {
  background: #333333;
  width: 1px;
  height: 25px;
  position: absolute;
  right: 56px;
  top: 12px;
  z-index: 999;
}
.SelectDuration {
  position: absolute;
  width: 45px;
  height: 41px;
  border: 0px;
  right: 4px;
  top: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  padding: 0px 7px;
}

.SelectDurationInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.SelectDurationInner p {
  margin-bottom: 0px;
}

.addVanPreviewImg {
  width: 87%;
  height: 90%;
  position: absolute;
}

.addVanPreviewImg img {
  width: 100%;
  height: 100%;
}
.previewImageCrossIcon {
  position: absolute;
  right: -37px;
  top: -23px;
  background-color: none;
  z-index: 999;
}
.vanUploadImg {
  width: 200px;
  height: 150px;
}

.vanUploadImg img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
}

.minTimeInput {
  background-color: #ffffff !important;
  width: 66px;
  height: 46px;
  top: 2%;
  position: absolute;
  right: 1%;
  z-index: 9999;
}
.leftTimeMain {
  padding: 15px;
}
.leftTimeBtn button {
  width: 150px;
  padding: 10px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
}

/* -----tutorial-- */

.tutorialPopupMain .vanUploadBtnInner {
  border: 1px dotted #6cadd3;
  border-radius: 4px;
  width: 72%;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
}

.tutorialPopupMain .vanUploadImg {
  width: 265px;
  height: 150px;
  border-radius: 9px;
  display: flex;
  align-items: start;
  align-items: flex-start;
}

@media (max-width: 575px) {
  .uploadBtnMain {
    left: initial;
  }
}

@media (max-width: 520px) {
  .tutorialPopupMain .vanUploadBtnInner {
    border: 1px dotted #6cadd3;
    border-radius: 4px;
    width: 80%;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 6px;
    position: relative;
  }

  .tutorialPopupMain .vanUploadImg {
    width: 250px;
    height: 150px;
    display: flex;
    align-items: start;
    align-items: flex-start;
    border-radius: 9px;
  }
}

@media (max-width: 475px) {
  .tutorialPopupMain .vanUploadBtnInner {
    width: 100%;
  }

  .tutorialPopupMain .vanUploadImg {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: start;
    align-items: flex-start;
    border-radius: 9px;
  }
}

@media (max-width: 425px) {
  .colorPopupInner {
    width: 100%;
  }
  .vanUploadBtnInner {
    width: 100%;
  }
  .MetaDatVanTableInner {
    margin-bottom: 0px;
  }

  .helperTableRowt h2 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #202020;
    margin-bottom: 0px;
  }

  .helperTableRowt button {
    border: 0.5px solid #0f7dff54;
    border-radius: 4px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #0f7dff;
    padding: 6px 18px;
    background: linear-gradient(
        0deg,
        rgba(15, 125, 255, 0.33),
        rgba(15, 125, 255, 0.33)
      ),
      linear-gradient(0deg, rgba(15, 125, 255, 0.13), rgba(15, 125, 255, 0.13));
  }
  .tutorialPopupMain .vanUploadBtnInner {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .tutorialPopupMain .vanUploadBtnInner {
    height: 170px;
  }
  .tutorialPopupMain .vanUploadImg video {
    width: 270px;
    height: 150px;
  }
}

@media (max-width: 375px) {
  .tutorialPopupMain .vanUploadImg video {
    width: 250px;
  }
}

@media (max-width: 350px) {
  .tutorialPopupMain .vanUploadImg video {
    width: 230px;
    height: 140px;
  }
}

@media (max-width: 325px) {
  .tutorialPopupMain .vanUploadImg video {
    width: 215px;
    height: 140px;
  }
}
