.commissionMainSection {
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 6px;
  padding: 30px 25px;
  max-height: 100%;
  width: 80%;
  overflow: hidden;
}
.commissionMainSection h5 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 60px;
}
.commissionMainSection h5::after {
  content: "";
  display: block;
  width: 50px;
  margin-top: 4px;
  padding-top: 1px;
  border-radius: 20px;
  border-bottom: 2px solid transparent;
  background: #0f7dff;
}
.AddVanBtn button {
  width: 100%;
  padding: 10px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  margin-top: 30px;
}

@media (max-width: 991px) {
  .commissionMainSection {
    min-width: 100%;
  }
}

@media (max-width: 375px) {
  .commissionMainSection {
    padding: 12px 12px;
  }
}
