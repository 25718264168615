.tabline {
  width: 1px;
  height: 10px;
  background-color: black;
}

.driverDetailsDate {
  height: 45px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  background-size: auto;
  border-radius: 4px;
  color: #757575;
  background-repeat: no-repeat;
  background-position: 90%;
  border: 0.8px solid #d0d0d0;
  z-index: 1;
}
.driverDetDocumentTabRow {
  width: 580px;
  display: flex;
  justify-content: start;
  align-items: flex-start;
}
.tableDate {
  color: #000000 !important;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  vertical-align: top;
}

.tableCategory {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #202020;
}

.tableAmount {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
  vertical-align: top;
}

.tableStatus {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  vertical-align: top;
}

/* -------review----- */
.reviewCard {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 30px;
}
.reviewCardTop {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.reviewTopLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 17px;
  margin-bottom: 15px;
}

.TopLeftSection img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.TopRightSection h3 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.TopRightSection h5 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  color: #626262;
  word-break: break-all;
}
.TopRightSection p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
  margin-bottom: 0px;
}
.reviewCardDescription {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  max-width: 80%;
  margin-bottom: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}

.jobListName {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #0f7dff !important;
}
.userDetailsHeading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.userDetailsHeading h1 {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  width: 35%;
  word-break: break-all;
  text-transform: capitalize;
}
.dote {
  text-align: start;
}
.dote ul {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 3px;
  padding: 0px;
  cursor: pointer;
  margin-bottom: 0px;
}
.dote ul li {
  list-style: none;
  width: 3.11px;
  height: 3.11px;
  border-radius: 100%;
  background-color: #333333;
}

.categegoryTabsInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0px 0px 4px 0px #00000040;
  padding: 16px 15px;
  margin-bottom: 20px;
  border-radius: 6px;
  background-color: #f7f7f7;
}

.categegoryTabsInner p {
  margin-bottom: 0px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.categegoryApprovedBtn {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #058321;
}
.categegoryRejectBtn {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #ec3b1e;
}

.categegoryAppRejBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.CategApprove {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #058321;
  text-decoration: underline;
  margin-right: 15px;
}

.CategReject {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ec3b1e;
  text-decoration: underline;
}

.availabilityItem {
  display: flex;
  flex-wrap: wrap;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #0f7dff;
  padding: 12px 0px;
}

.availabilityItem span {
  display: flex;
  flex-wrap: wrap;
  font-family: Inter;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 700;
  color: #343434;
}
.availabilityTO {
  margin-bottom: 0px;
}

.availabilityClosed {
  margin-bottom: 0px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  color: #ec3b1e;
}

.documentExpirationDate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
}
.documentExpirationDate span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  color: #333333;
}
.documentExpirationDate span p {
  margin-bottom: 0px;
}

.backBtnMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.backBtnInnarLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.vehicleTableBtn button {
  padding: 6px 10px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: white;
  background-color: #fd9700;
  border: 0.5px solid white;
  border-radius: 6px;
}

.vehicleTableRejBtn button {
  padding: 6px 10px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: white;
  background-color: #ec3b1e;
  border: 0.5px solid white;
  border-radius: 6px;
}

.vehicleTableAppBtn button {
  padding: 6px 10px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: white;
  background-color: #058321;
  border: 0.5px solid white;
  border-radius: 6px;
}

.currancySymbol {
  font-family: Inter;
  font-size: 13.3px;
  font-weight: 600;
  color: #202020;
}

.driverDetailFilter {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 26px;
}
.selectDateRange {
  display: grid;
}

.selectDateRange input {
  height: 45px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  background-size: auto;
  border-radius: 4px;
  color: #757575;
  width: 100%;
}

.releasePaymentBtn {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 10px 0px 0px;
  flex-wrap: wrap;
}
.releasePaymentBtn span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-right: 15px;
  margin-bottom: 10px;
}

.releasePaymentBtn span p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  color: #626262 !important;
  margin-right: 6px;
}

.releasePaymentBtn button {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  background-color: #0094f6;
  color: white;
  border-radius: 6px;
  border: none;
  padding: 7px 20px;
  margin-bottom: 10px;
}

.tableCheckBox {
  margin-top: 5px;
}
.tableCheckTd {
  position: relative !important;
}

.tableCheckTdInput {
  position: absolute !important;
  bottom: 5%;
  z-index: 0 !important;
  cursor: pointer;
}

.tableCheckTdInputTh {
  position: absolute !important;
  bottom: 4%;
  z-index: 5 !important;
  cursor: pointer;
  margin-bottom: -7px;
}

.releasePaymentBtnCheckBox {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
}

/* ----businessLocation--- */
.businessLocation {
  margin-top: 10px;
  /* width: 250px; */
  margin-bottom: 10px;
}
.businessLocation p {
  color: #3f3e3e;
  word-break: break-all;
}
.businessLocation h5 {
  margin-bottom: 0px;
  color: #626262;
}

@media (max-width: 1200px) {
  .colRemoveResponsive {
    display: none;
  }
  .userDetailsBtn {
    text-align: start;
  }
}
@media (max-width: 991px) {
  .driverDetDocumentTabRow {
    display: flex;
    justify-content: start;
    align-items: flex-start;
  }
  .reviewCard p {
    max-width: 100%;
  }
  .userDetailsHeading h1 {
    font-size: 26px;
  }
  .userDetailsHeading h1 {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .userDetailsHeading h1 {
    font-size: 24px;
  }
  .categegoryTabsInner p {
    font-size: 14px;
  }
  .categegoryApprovedBtn {
    font-size: 14px;
  }
  .categegoryRejectBtn {
    font-size: 14px;
  }
  .vehicleTableBtn button {
    padding: 4px 10px;
  }
  .userDetailsHeading h1 {
    width: 75%;
  }
}

@media (max-width: 425px) {
  .reviewTopLeft {
    justify-content: flex-start;
  }
}

@media (max-width: 375px) {
  .userDetailsProfileInner {
    padding: 50px 0px 25px 0px;
  }
  .userDetailsHeading h1 {
    font-size: 18px;
  }
}
@media (max-width: 327px) {
  .categegoryTabsInner p {
    margin-bottom: 10px;
  }
  .userDetailsHeading h1 {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .userDetailsHeading h1 {
    font-size: 20px;
  }
  .jobListName {
    font-size: 16px !important;
  }
  .userDetailsHeading h1 {
    width: 90%;
  }

  .releasePaymentBtn span p {
    margin-right: 0px;
    font-size: 14px;
  }

  .releasePaymentBtn span {
    display: initial;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-right: 0px;
    margin-bottom: 10px;
    text-align: end;
  }
  .releasePaymentBtn button {
    font-size: 13px;
  }
  .businessLocation {
    width: 190px;
  }
}
.accountInfoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  margin-bottom: 32px;
}
