.formBlock {
    border-radius: 10px;
    max-width: 425px;
    margin: 0 auto;
    background: #FFFFFF;
    padding: 30px 20px;
    box-shadow: 0px 3px 12px 0px #FFFFFF33;
}

.resetImage {
    width: 100%;
    max-width: 133px;
    margin: 12px auto;
    background: linear-gradient(0deg, #0F7DFF, #0F7DFF), linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
    border-radius: 50%;
    height: 133px;
}
.loginMain {
    width: 100%;
    padding: 89.5px 0px;
    /* height: 100vh; */
    background-color: #0094f6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.resetImage img {
    width: 100%;
    width: 90px;
    margin: 45px 19px;
}

.resetText h3 {
    color: #000000;
    font-size: 26px;
    font-weight: 700;
    line-height: 49px;
    text-align: center;
}

.loginBtn button {
    font-weight: 600;
    font-size: 16px;
    line-height: 21.78px;
    color: #FFFFFF;
    border: 1px solid #0F7DFF70;
    background: linear-gradient(0deg, #0F7DFF, #0F7DFF), linear-gradient(0deg, rgba(15, 125, 255, 0.44), rgba(15, 125, 255, 0.44));
    height: 50px;
    width: 100%;
    border-radius: 1px;
    margin-top: 35px;
    border-radius: var(--bs-border-radius);
}

@media only screen and (max-width: 575px) {

    .formBlock {
        padding: 30px 20px;
    }
}