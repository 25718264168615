/* ----copyright--- */
.copyrightSection {
  background-color: #202020;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0px solid white;
}
.copyrightSection p {
  margin-bottom: 0px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 0px;
  text-align: center;
}
@media (max-width: 775px) {
  .copyrightSection p {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  .copyrightSection p {
    font-size: 12px;
  }
}
