.furnitureSection {
  background-color: #fbfbfb;
}
.furnitureWrapper {
  margin: 0px 20px;
}
.furnitureHeading {
  font-size: 16;
  font-weight: 500;
}
.furnitureFields {
  background-color: white;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin-top: 6px;
}
.subFurnitureFields {
  margin: 0px 22px;
  padding-top: 25px;
}
.furnitureName {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}
.furnitureType {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  cursor: pointer;
  padding: 12px;
}
.subTypeSection {
  display: flex;
  align-items: center;
  gap: 8px;
}

.categoryIcon img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.thirdTabFieldsMain {
  background-color: #fbfbfb;
  /* padding: 20px 90px 0px 90px; */
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 500px) {
  .furnitureWrapper {
    margin: 0px 10px;
  }
  .thirdTabFieldsMain {
    padding: 10px 10px 0px 10px;
  }
}

.thirdTabFields {
  background-color: #f8f8f8;
  padding: 0px 20px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  position: relative;
}

.thirdTabFields .crossIcon {
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: -10px;
}

.thirdAddBtn {
  margin-bottom: 32px;
}
.dimensionHead {
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}
.categoryBox {
  margin-top: 20px;
  background-color: #f8f8f8;
  padding: 0px 20px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.categoryName {
  font-size: 18px;
  font-weight: 600;
  margin: 12px 0px;
}
.vehicleSectionWrapper {
  margin: 10px 20px 10px 20px;
}

/* @media screen and (max-width: 375px) {
  .vehicleSectionWrapper {
    margin: 20px 40px 20px 40px;
  }
} */
.vehicleSection {
  background-color: aqua;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
}

.viewGuideHead {
  font-size: 22px;
  font-weight: 600;
}

.vanSizeGuide {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  font-weight: 700;
  color: #4770eb;
  text-decoration: underline;
  margin-bottom: 8px;
}

.vanSizeGuide span {
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  background: linear-gradient(to right, #4770eb, #6931df);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.oddVehicleRow {
  justify-content: space-between;
  align-items: center;
  min-height: 196px;
}
.oddFirst {
  /* order: 2; */
}
.evenVehicleRow {
  justify-content: space-between;
  align-items: center;
  min-height: 196px;
}

.vehicleTextView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.vehicleViewTitle {
  font-size: 15px;
  font-weight: 700;
}

.vehicleViewDesc {
  font-size: 13px;
  font-weight: 500;
  color: #626262;
}

.vehicleViewInfo {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}

.vehicleImage {
  height: 100px;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}

@media screen and (max-width: 350px) {
  .vehicleImage {
    height: 80px;
    width: 120px;
  }
}

.vehicleName {
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vehicleTitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  /* margin: -8px 0px 0px 0px; */
  line-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  min-height: 60px;
}
.formBlock {
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  background: #ffffff;
  padding: 10px 20px 20px 20px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
}

@media screen and (max-width: 735px) {
  .formBlock {
    max-width: 400px;
  }
}

@media screen and (max-width: 500px) {
  .formBlock {
    max-width: 300px;
  }
}

.availabiltyTitle {
  font-size: 18px;
  font-weight: 500;
}
.estimatedTime {
  font-weight: 500;
  color: #3159d2;
}
.packingSection {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
}
.fifthNextBtn {
  width: 100%;
  height: 50px;
}
.searchGroupIcon {
  background-color: transparent;
}

.nextBtnFooterSecond {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
}

.addOwnItemBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  color: #4770eb;
  cursor: pointer;
}

.addOwnItemBtnText {
  font-size: 17px;
  font-weight: 600;
  background: linear-gradient(to right, #4770eb, #6931df);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chatFeatureSecond {
  text-align: end;
  margin-right: 24px;
  cursor: pointer;
  /* margin-bottom: 12px; */
  padding-bottom: 12px;
  margin-top: 12px;
}

.timeEstimation {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 500px) {
  .timeEstimation {
    flex-direction: column;
    align-items: flex-start;
  }
}

.topHeadingVan {
  display: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0px 10px;
}

@media screen and (max-width: 470px) {
  .topHeadingVan {
    display: flex;
    justify-content: space-between;
  }
}
.formateText {
  margin-top: 8px;
  margin-bottom: 12px;
}
.selectPeopleTitle {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}
@media screen and (max-width: 500px) {
  .selectPeopleTitle {
    font-size: 16px;
  }
}
