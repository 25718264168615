.faqHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.faqHeading h1 {
  font-family: Inter;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #333333;
}
.faqHeading span {
  font-family: Inter;
  margin-bottom: 10px;
  font-weight: 600;
  color: white;
  background-color: #0f7dff;
  border-radius: 4px;
  padding: 6px 20px;
  cursor: pointer;
}
.faqWrapper {
  background-color: #fcfcfc;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
}
.faqTitle {
  color: #828282;
  font-weight: 600;
}
.faqAnswer {
  color: #333333;
  font-weight: 400;
  font-size: 15px;
}
.faqInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}
.faqInfo svg {
  cursor: pointer;
}

.faqSubInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dividerLine {
  height: 1px;
  background-color: #d9d9d9;
}
.AddFAQBtn button {
  width: 100%;
  padding: 12px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
}

@media screen and (min-width: 900px) {
  .faqInfo {
    gap: 40px;
  }
}
