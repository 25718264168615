.deletedHeading {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #cfcfcf !important;
  border-radius: 5px !important;
  height: 40px;
  background-color: #f7f7f7;
  align-items: center;
  margin: 0px 0px 32px 0px;
  font-weight: 600;
  color: #555555;
}

.headTitle {
  cursor: pointer;
}
