.sidebarProfileBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0% 10% 1%;
  border-bottom: 1px solid white;
}
.sidebarProfileBoxInner {
  width: 49%;
  height: 49%;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebarProfileBoxInner img {
  width: 100%;
  height: 100%;
}

.sidebarbuttonbox {
  padding: 20px 0px 20px 20px;
}

.logoutMobile {
  display: none;
}

@media (max-width: 991px) {
  .logoutMobile {
    display: initial;
  }
}
