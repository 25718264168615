/* ----header----- */

.headersection nav {
  padding: 6px 12px;
  border-bottom: 1px solid #dbdbdb;
  background: #fff !important;
}

.headersection .navbar-brand {
  height: 75px;
}
.headersection .navbar-brand img {
  width: 210px;
  height: 68px;
  padding: 6px;
  object-fit: contain;
}
.headersection nav {
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  padding: 0px 30px;
}
.navbar-collapse {
  justify-content: end;
  align-items: end;
}

.navbar-toggler:focus {
  box-shadow: unset !important;
}

/* -----footer----- */

.footerSection nav {
  padding: 6px 12px;
  /* border-bottom: 1px solid #dbdbdb; */
  background: #202020 !important;
  color: white !important;
}

.footerSection .navbar-nav {
  flex-direction: row;
  flex-wrap: wrap;
}

.footerSection .navbar-brand img {
  width: 200px !important;
  height: 55px;
  object-fit: contain;
}

.footerSection .navbar-collapse {
  justify-content: end !important;
  align-items: end;
}

.footerSection .nav-link {
  color: white !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  margin-right: 12px;
}

/* -----dashboard----*/
.adminPageWrapper {
  width: 100%;
  height: 100vh;
}
.adminPageInner {
  height: 100%;
  display: flex;
}
.adminPageInner_rightside {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.adminPageInner_heading {
  padding: 30px;
  height: 90vh;
  overflow-y: scroll;
}

.adminPageInner_heading::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.adminPageInner_heading::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.adminPageInner_heading::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

/* -----dashboard--table-------- */

.tableMainSection {
  width: 100%;
}
.DashboardTableInner {
  max-height: 70vh;
  overflow-y: auto;
}

.DashboardTableInner::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

/* Track */
.DashboardTableInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.DashboardTableInner::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.tableMainSection .tableMainInner {
  border: 1px solid #8b8b8b;
  border-radius: 10px;
  overflow: hidden !important;
  box-shadow: 0px 0px 4px 0px #00000026;
  margin-bottom: 0px;
}
.tableBootomScroll {
  overflow-x: scroll !important;
}

.tableBootomScroll::-webkit-scrollbar {
  height: 9px;
  border-radius: 50px;
  margin: 0px 10px;
}
.tableBootomScroll::-webkit-scrollbar-track {
  background-color: transparent;
  padding: 0px 10px;
  margin: 0px 5px;
  border-radius: 50px;
}

.tableBootomScroll::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  padding: 0px 10px;
  border-radius: 50px;
}

.tableMainInner h2 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 15px;
  margin-bottom: 0px;
  color: #000000;
}

.DashboardTableInner .tableDashtd {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  color: #202020;
  height: 48px;
}

.DashboardTableInner .tablethleft {
  border-top-left-radius: 10px;
}

.DashboardTableInner .tablethRight {
  border-top-right-radius: 10px;
}

.tableMainSection .tabledashboard {
  width: 100%;
}

.dashboardTdSource {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  color: #202020;
  vertical-align: top;
}

.tableTdStops {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  vertical-align: top;
}

.leftsidepadding {
  padding-left: 15px !important;
}
.rightsidepadding {
  padding-right: 15px !important;
}

/* --------Pagination-------- */
.tablePaginationDash {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 22px;
}
.tablePaginationDash .pagination {
  margin-bottom: 0px;
}
.PaginationDashInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PaginationDashInner p {
  margin-bottom: 0px;
  padding: 0px 10px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
}

.paginationPrev {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c1c1c1;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
.paginationNext {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0094f6;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

/* -----sidebar-new------- */

.sidebar-content {
  padding: 0px;
  height: 100vh;
}
.sc-fBWQRz {
  overflow-y: scroll;
}

.fRhOkl {
  height: 100vh;
  overflow-y: scroll;
}
.sidebar-content .eDStxa.toggled .item-content {
  width: 0px;
  display: initial;
  border-radius: 100px;
  margin-right: 14px;
  height: 44px;
}

.sidebaricon_line {
  font-size: 25px;
}

.sidebar-content .gmeTLe {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.sidebar-content a.active .gmeTLe {
  background-color: white;
  color: #0094f6;
}

.sidebar-content .activeClicked .gmeTLe.active {
  color: #0094f6;
}

.sidebar-content .hQSxZz {
  margin: 10px 0px 10px 15px;
}

.sidebar-content .gmeTLe.toggled {
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 7px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
}

.sidebar-content .gmeTLe .side-icon {
  margin-right: 15px;
  width: 30px;
  font-size: 19px;
}

.sidebar-content a.active .gmeTLe svg path {
  fill: #0094f6;
  width: 20px;
  height: 20px;
}

.sidebar-content .gmeTLe {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px 0px 8px 5px !important;
  height: 44px;
  cursor: pointer;
  outline: none;
}

.sidebar-content .gmeTLe .item-content {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
}

.sidebar-content .gmeTLe.toggled .item-content {
  width: 3px;
  display: initial !important;
  border-radius: 100%;
}

.sidebar-content .sidebarAction {
  padding: 0px 2px !important;
}

.sidebarAction svg {
  width: 30px;
  height: 30px;
}

.sidebarSection {
  height: 100vh !important;
}

.sidebar-content::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebar-content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.sidebar-content::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.sidebarSection ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.sidebarSection ul a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.sidebarSection ul li {
  margin: 10px 0px 10px 0px;
}

.sidebarSection ul li.toggled {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0px;
}

.iMbSSf.toggled::before {
  content: "";
  display: inline-block;
  position: absolute;
  inset: 0px;
  cursor: pointer;
}

.iMbSSf.toggled {
  position: relative;
  margin-top: 0px;
  margin-bottom: 10px;
  z-index: 5;
  background-color: #0094f6;
}

/* --update--- */
.eDStxa.toggled .item-content {
  width: 36px;
  display: initial;
}

.allBtnPaddingLeft {
  margin: 10px 0px 10px 15px;
}

.eDStxa {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px 0px 8px 15px;
  height: 45px;
  cursor: pointer;
  outline: none;
}

/* -------userSection---- */
.topSearchSectionInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.topSearchSectionInner h1 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 15px;
}
.topSearchMain {
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 15px;
  background: #f3f3f3;
  margin-bottom: 15px;
}

.topSearchMain .form-control {
  border: none;
  background: #f3f3f3;
  width: 450px;
}

.topSearchMain .form-control::placeholder {
  font-family: Inter;
  font-size: 15px;
  font-weight: 300;
  color: #555555;
}

.form-control:focus {
  box-shadow: none;
}

/* -----user-table---- */
.SupporTableInner .UserTableInner {
  max-height: 65vh;
  overflow-y: auto;
}

.UserTableInner::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

/* Track */
.UserTableInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.UserTableInner::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

/* -----table-comman--- */
.tableMainInner .tableThBg {
  background: #555555;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: none;
  height: 48px;
  position: -webkit-sticky;
  position: sticky !important;
  top: 0 !important;
  z-index: 5 !important;
}

.tableMainInner .tableTdText {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #202020;
  word-break: break-word;
}

.tableImgMain span {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.tableImgMain span img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

/* --------userDetails----- */
.userDetailsTable {
  max-height: 75vh;
  overflow-y: auto;
}

.userDetailsTable::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

/* Track */
.userDetailsTable::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.userDetailsTable::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.UserDetailsMainSection {
  overflow: hidden;
}

.userDetailsBackBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.userDetailsBackBtn a {
  text-decoration: none;
}
.userDetailsBackBtn a svg {
  width: 50px;
  height: 50px;
}
.userDetailsBackBtn h1 {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 0px;
  margin-left: 12px;
}
.userDetailsBackBtn span svg {
  width: 42px;
  height: 42px;
}

/* ----userDetailsProfile---- */
.userDetailsProfile {
  position: relative;
  height: 100%;
  padding: 90px 0px 0px 0px;
  margin-bottom: 25px;
}

.userDetailsProfileInner {
  background-color: #f7f7f7;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0px 25px 0px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #00000040;
}
.userDetailsProfileInner .userDetailsProfileImg {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background: linear-gradient(88.85deg, #4770eb 0.73%, #6931df 99.01%);
  position: absolute;
  top: 0px;
  background-image: url(../Images/UserDetailsimage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.driverDetailsImg {
  position: absolute;
  top: 0;
}
.driverDetailsImg img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  object-fit: cover;
}

.userDetailsProfileInner h1 {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
  word-wrap: break-word;
  width: 35%;
  text-transform: capitalize;
}
.userDetailsProfileInner p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #626262;
  margin-bottom: 10px;
  word-break: break-all;
}
.userDetailsProfileInner h5 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #0f7dff;
  margin-bottom: 0px;
}
.userDetailMain {
  overflow: hidden;
  padding: 2px;
}

.userDetailMain .topSearchSectionInner h1 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #0f7dff;
}

/* ----userDetailsdropdown----- */

.UserDetailDropMenu {
  background-color: #f4f4f4;
  border-radius: 6px;
  border: 0.6px solid #d7d7d7;
  padding: 20px 20px 10px 20px;
  margin-bottom: 20px;
}

.inputDrop {
  height: 45px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  background-size: auto;
  border-radius: 4px;
  color: #757575;
  background-image: url(../Images/drop_Down_Arrow_icon.png);
  border: 0.8px solid #d0d0d0;
}

.vanDrop {
  height: 45px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  background-size: auto;
  border-radius: 4px;
  color: #333333;
  background-image: url(../Images/drop_Down_Arrow_icon.png);
  border: 0.8px solid #d0d0d0;
  margin-bottom: 15px;
}

.vanDrop :focus {
  border: 0.8px solid #d0d0d0;
  box-shadow: unset !important;
}

.DateIcon {
  background-size: auto;
  background-image: url(../Images/dateIcon.png);
}

.filterBtn {
  background: #0094f6;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #ffffff;
  padding: 9px 3px;
  border-radius: 3px;
  border: 0.8px solid #0094f6;
  width: 51%;
  text-align: center;
}
.resetBtn {
  color: #0094f6;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 9px;
  border-radius: 3px;
  border: 0.8px solid #0094f6;
  width: 46%;
}

.userDetailMain .topSearchSectionInner h1 {
  margin-bottom: 20px;
}
.userDetailMain .topSearchMain {
  margin-bottom: 20px;
}

/* -----driver---- */
.DriverTableInner {
  max-height: 60vh;
  overflow-y: auto;
}

.DriverTableInner::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

/* Track */
.DriverTableInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.DriverTableInner::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.userDetailsBtn {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.driverMainSection {
  overflow: hidden;
}

.driverMainSection .UserTableInner {
  margin-bottom: 10px;
}

.driverDetailsProfil .userDetailsProfileInner {
  background-color: #f7f7f7;
  display: block;
  padding: 25px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #00000040;
}

.ProfileTopInnerLeft {
  text-align: start;
}

.driverDetailsProfil .ProfileTopInnerLeft p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #626262;
  margin-bottom: 2px;
}
.driverDetailsProfil .ProfileTopInnerLeft h1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #058321;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.driverDetailsProfil .ProfileTopInnerRight h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Proxima Nova;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  gap: 10px;
}
.ProfileTopInnerRight {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.ProfileTopInnerRight ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0px;
  padding: 0px;
  gap: 5px;
}

.ProfileTopInnerRight ul li {
  list-style: none;
  height: 31px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfileTopInnerRight ul li svg {
  height: 28px;
  width: 28px;
}

/* -----driverDetails----  */

.userDetailsTabsMain .nav.nav-tabs.nav-justified {
  padding: 8px 0px;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
}

.userDetailsTabsMain .nav-tabs .nav-link {
  border-right: 1px solid #555555;
  padding: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top: 0px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #555555;
  border-bottom: none;
}
.userDetailsTabsMain .nav-tabs .nav-link.active {
  color: #0094f6;
  border-left: 1px solid white;
}
.userDetailsTabsMain button#justify-tab-example-tab-Review {
  border-right: none;
}
.driverDetailsMainSection {
  overflow: hidden;
  padding: 2px;
}
.driverDetailsMainSection .textDescription {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  color: #343434;
}
.vehicleInfoheight {
  height: 70px;
}
.UserDetailDropMenu .form-label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

/* --------.driverDetaildCategory------ */

.driverDetaildCategory .nav-pills .nav-link {
  border-radius: 6px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 20px;
  box-shadow: 0px 0px 4px 0px #00000040;
  background-color: #f7f7f7;
  padding: 15px 15px;
}

.driverDetaildCategory .nav-pills .categorybtn {
  box-shadow: none !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  background-color: transparent !important;
  background-color: none !important;
  color: #000000;
  margin-bottom: 20px;
}

.driverDetaildTabBtn {
  width: 100%;
  flex-wrap: wrap;
}
.driverDetaildTabBtn h1 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.driverDetaildTabBtn_Right {
  gap: 20px;
  margin-bottom: 20px;
  padding: 0px 5px;
}
/* -- */
.userProfileTop {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.driverDetaildCategory .nav-pills .nav-link.active {
  font-weight: 400;
}

/* -----------Document----driverDetails------ */

.driverDetDocumentTab {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.driverDetDocumentTabInner h4 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: #0094f6;
  text-align: center;
}
.driverDetCardImg {
  position: relative;
}
.driverDetCardImg img {
  width: 100%;
  height: 162px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  border: 1px solid #6cadd3;
  margin-bottom: 15px;
  z-index: 1;
  object-fit: cover;
}

.driverDetCardImgOverlay span {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 162px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  cursor: pointer;
}
.driverDetCardImgOverlay span path {
  fill: white;
}

.driverDetCardBtn .rejectBtn {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 18px;
  border: 1px solid #ec3b1e;
  border-radius: 3px;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
}

.driverDetCardBtn .rejectBtn span svg path {
  width: 20px;
  height: 20px;
}

.driverDetCardBtn .rejectBtn p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  color: #ec3b1e;
  margin-bottom: 0px;
}

.driverDetCardBtn .approveBtn {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 13px;
  border: 1px solid #058321;
  border-radius: 3px;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
}

.driverDetCardBtn .approveBtn span svg path {
  width: 20px;
  height: 20px;
}

.driverDetCardBtn .approveBtn p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  color: #058321;
  margin-bottom: 0px;
}
/* -----reivew---- */
.reviewTopRight .ProfileTopInnerRight ul li {
  margin-right: 6px;
  list-style: none;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ------Benquiry---- */
.BenquiryMainSection .topSearchSectionInner h1 {
  margin-bottom: 25px;
}

/* ---metadataTapLeft--- */
.metadataMinSection {
  width: 100%;
  height: 100%;
}

.categoryTapLeft .metadataTapLeft {
  padding: 15px 15px 0px 15px;
}

.metadataTapLeft {
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  background-color: #fcfcfc;
  box-shadow: 0px 0px 2px 0px #00000026;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 0px 0px 15px 0px;
}
.metaDataTitle h1 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  padding: 20px 15px;
  border-bottom: 1px solid #626262;
  margin-bottom: 12px;
}

.metadataTapLeft .nav-pills .nav-link {
  border-radius: 0px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #555555;
}

.metadataTapLeft .nav-pills .nav-link.active {
  background-color: #0094f6;
  color: white;
}

.addVanPopupMain .modal-body {
  padding: 30px;
}

.addVanPopupMain .modal-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.popupInput {
  margin-bottom: 25px;
}

.popupInput .form-label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}

.popupInput .form-control {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 10px;
}
.popupInput .form-control::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  color: #555555;
}

.addVanPopupMain svg {
  color: #0094f6;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.helperPopupMain .addVanPopupMain .modal-body {
  padding: 30px 30px 30px 30px;
}

.helperPopupMain .popupInput {
  margin-bottom: 50px;
}

/* ------VAN-min--- */
.CreateInput {
  text-align: start;
  margin-bottom: 20px;
  position: relative;
}
.CreateInput label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.CreateInput .form-control {
  position: relative;
  width: 100%;
  height: 49px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 10px;
}
.CreateInput .form-control::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  color: #555555;
}
.floorPopupMain .helperPopupMain .popupInput {
  margin-bottom: 12px;
}

.floorPopupMain .CreateInput {
  margin-bottom: 30px;
}

.floorPopupMain .form-select {
  background-image: none;
}

/* -----Category----- */

.categoryMinSection .metaDataTitle h1 {
  border-bottom: 0px solid #626262;
  font-size: 20px;
  margin-bottom: 0px;
}
.categoryMinSection .nav-item {
  margin-bottom: 20px;
}
.categoryMinSection .nav-item .nav-link {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 10px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

.categoryMinSection .metadataTapLeft .nav-pills .nav-link span svg {
  font-size: 28px;
  margin-right: 10px;
}

.categoryMinSection .metadataTapLeft .nav-pills .nav-link img {
  font-size: 28px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  /* box-shadow: 0px 0px 4px 0px #00000026; */
  /* border-radius: 50%; */
  padding: 6px;
}

.categoryMinSection .metadataTapLeft .nav-pills .nav-link.active span svg {
  fill: white;
}
.categoryMinSection .metadataTapLeft .nav-pills .nav-link span img {
  fill: #555555;
  margin-right: 15px;
  width: 42px;
  height: 42px;
}

.categoryMinSection .nav-item .nav-link p {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}
/* ----commision--- */
.commissionSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.commissionSection .form-control {
  padding: 15px 10px;
}

/* ------broadcasting----- */
.broadcasting .topSearchMain .form-control {
  width: 100%;
}
.broadcasting .topSearchSectionInner {
  display: initial;
}
.broadcasting .form-check-input {
  border-color: #828282;
  border-radius: 0px;
  border-width: 2px;
  cursor: pointer;
}
.broadcasting .form-check-input:checked {
  border-width: 0px;
}

.broadcastingCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 15px 18px;
  border-bottom: 1px solid #d9d9d9ed;
}

.broadcastingCard .form-check-input {
  border-color: #828282;
  border-radius: 0px;
  border-width: 2px;
  cursor: pointer;
}
.broadcastingCard .form-check-input:checked {
  border-width: 0px;
}

/* -----jobDetails--- */
.hh.adminPageInner_heading {
  background-color: #f4f4f4;
  padding: 0px !important;
}
.jobDetailsSection .userDetailsBackBtn {
  background-color: white;
  padding: 25px;
  margin-bottom: 0px;
}
.jobDetailsInner {
  padding: 10px 25px;
}
.tableMainInner .jobDettable {
  border: 0.3px solid #333333;
  background: #333333;
  color: white;
}
.jobdetailsTableInner {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 50vh;
}

.jobdetailsTableInner::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

/* Track */
.jobdetailsTableInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.jobdetailsTableInner::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.jobdetailsTableInner .tablethleft {
  border-top-left-radius: 0px !important;
}

.jobdetailsTableInner .tablethRight {
  border-top-right-radius: 0px !important;
}

.jobdetailsTableInner .jobDettable {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  background: #333333;
  color: white;
}
.subCategoryPadding {
  padding: 10px 15px !important;
}

.tableMainInnerNew .jobdetailsTableInner .jobDettabletableTdText {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #202020;
}

.tableMainSection .tableMainInnerNew {
  overflow: hidden;
}

.react-colorful {
  display: none;
}

/* -----liveChat---- */

.liveChatMainSection {
  overflow: hidden;
}

.livechatRight {
  border: 0.3px solid #c0c0c0;
  box-shadow: 0px 0px 4px 0px #00000026;
  border-radius: 6px;
  margin-bottom: 25px;
  overflow: hidden;
  height: 100vh;
}

.livechatRight .form-control {
  border-radius: 100px !important;
  background-color: #f1f1f1;
  margin-right: 10px;
  border: none;
  padding: 0px 2px;
}

.BenquiryMainSection {
  overflow: hidden;
}

/*---------bellIconMessageSection----- */
.bellIconMessageSection {
  box-shadow: 0px 0px 4px 0px #0f7dff;
  background-color: #f7f7f7;
  border-radius: 6px;
  transform: translate3d(253%, 86px, 0px) !important;
  width: 27% !important;
}
.tooltip {
  opacity: 10 !important;
}

.bellIconMessageSection .tooltip-inner {
  background-color: #f7f7f7;
  color: #000000;
  max-width: 100%;
  padding: 20px 20px 10px 20px;
  overflow-y: scroll;
  height: 70vh;
}

.bellIconMessageSection .tooltip-arrow::before {
  position: absolute !important;
  content: "" !important;
  border-width: 8px 9px 16px 9px !important;
  border-style: solid !important;
  border-width: 11px !important;
  transform: translate3d(280%, -1px, 0px) !important;
  border-bottom-color: #f3f0f0 !important;
}

.bellIconMessageSection .tooltip-inner::-webkit-scrollbar {
  width: 6px;
  border-radius: 50px;
}

.bellIconMessageSection .tooltip-inner::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.bellIconMessageSection .tooltip-inner::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 50px;
}

/* -----forget---- */
.formTopSpace .popupInput {
  margin-bottom: 60px;
}
/* otp input css */

.otpbox input::placeholder {
  font-size: 25px;
  color: #878787;
}

.otpInput {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.inputStyle {
  width: 40px !important;
  height: 40px !important;
  margin: 0 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.inputStyle:focus-visible {
  box-shadow: none !important;
  outline: none;
}

.formTopSpace input {
  color: #7a7a7a;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  height: 45px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  border: 1px solid #bfbfbf;
  background: #ffffff;
}

.setIconPassword {
  position: relative;
}

.passwordicon {
  right: 13px;
  top: 39px;
  font-size: 22px;
  position: absolute;
  cursor: pointer;
  color: #afaeae;
}

.formSection label {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

.formSection input {
  color: #7a7a7a;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  height: 45px;
  box-shadow: 0px 3px 12px 0px #ffffff33;
  border: 1px solid #bfbfbf;
  background: #ffffff;
}

.formSection .form-control {
  display: block;
  width: 100%;
  padding: 12px 0.75rem;
}

/* -----table-dropdown---- */

.TableDropdown .dropdown-toggle::after {
  display: none;
}
.TableDropdown .dropdown .btn-primary {
  background-color: transparent;
}

.TableDropdown .dropdown-toggle {
  background-color: transparent;
  border: none;
}

.TableDropdown .dropdown-menu {
  min-width: 100px !important;
  box-shadow: 0px 0px 2px 0px #00000026;
}
.TableDropdown .dropdown-item {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #202020;
  background-color: transparent;
}
.TableDropdown .dropdown-item:hover {
  background: #949494;
  color: white;
}

.helperSectionInner {
  width: 100%;
  overflow-y: auto;
  max-height: 70vh;
}

.helperSectionInner::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

/* Track */
.helperSectionInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* Handle */
.helperSectionInner::-webkit-scrollbar-thumb {
  background: rgb(187, 186, 186);
  border-radius: 50px;
}

.FloorSection .tableMainSection .tableMainInner {
  border: 0.3px solid #c0c0c0;
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden !important;
  box-shadow: 0px 0px 4px 0px #00000026;
}

.floorTableTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  background: #333333;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* .progressBar .MuiStepConnector-alternativeLabel {
  top: 30px;
  position: absolute;
  left: calc(-46% + 20px);
  right: calc(54% + 20px);
} */

/* .progressBar .MuiSvgIcon-fontSizeMedium {
  width: 30px;
  height: 30px;
} */

/* ----------- */

.optionTopMain svg {
  color: #333333;
  position: absolute;
  top: 6px;
  left: 0px;
}

.ant-picker-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  padding: 9px 18px;
}

.ant-picker .ant-picker-input > input::placeholder {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  color: #757575;
}
.ant-picker .ant-picker-suffix svg {
  fill: #757575;
}
.anticon svg {
  fill: #757575;
}

.ant-picker-outlined:hover {
  background-color: #ffffff;
}
.SearchMainSec {
  display: flex;
  justify-content: space-between;
}
.SearchMainSec .topSearch {
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 5px 5px 15px;
  background: #f3f3f3;
  margin-bottom: 15px;
  width: 55%;
}

.mmm .modal-dialog {
  transform: none;
  max-width: inherit;
  width: 73% !important;
}

.addVanPopupMain .topSearch .form-control {
  border: none;
  background: #f3f3f3;
}

/* ----support---- */

.supportTabsMain .userDetailsTabsMain .nav.nav-tabs.nav-justified {
  padding: none !important;
  border: 1px solid #cfcfcf !important;
  border-radius: 5px !important;
  height: 40px;
  background-color: #f7f7f7;
}
.supportTabsMain .userDetailsTabsMain .nav-tabs .nav-link {
  border-right: none !important;
  padding: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top: 0px !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #555555 !important;
  border-bottom: none !important;
  background-color: #f7f7f7;
}

.supportTabsMain .userDetailsTabsMain .nav-tabs .nav-link.active {
  color: #0094f6 !important;
  border-left: 1px solid white !important;
  border-right: 1px solid #555555 !important;
}

button#justify-tab-example-tab-Driver_Support {
  border-right: 1px solid #555555 !important;
}

.UserTableInner {
  max-height: 65vh !important;
  overflow-y: auto !important;
  min-height: 190px;
}

.page-link:focus {
  box-shadow: unset !important;
}

.activeCls {
  border: 1px solid #0f7dff !important;
}

.activeCls p {
  color: #0094f6 !important;
  transition: 0.3s ease-out;
}

.optionList svg {
  color: #333333;
}

.activeClass {
  color: green !important;
}

.InactiveClass {
  color: red !important;
}

.eyeIcon {
  cursor: pointer;
}

.inputDrop :focus {
  border: 0.8px solid #d0d0d0;
  box-shadow: unset !important;
}
.form-select:focus {
  border-color: #d0d0d0;
  box-shadow: unset !important;
}

.PendingClass {
  color: #fd9700;
}

.ColorEditPopup .popupInput {
  margin-bottom: 20px;
}

.textUpperLatter {
  text-transform: capitalize;
}

.centeredCell {
  border-bottom: unset !important;
  --bs-table-bg-type: unset !important;
}
.dataRecord {
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 600;
  color: #555555;
}
.availabilitydataRecord {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
  color: #555555;
}

.page-link {
  width: 40px;
  height: 39px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-link svg {
  font-size: 35px;
}

.centeredCellJobDetails {
  border-bottom: unset !important;
  --bs-table-bg-type: unset !important;
}

.jobDetailsTableRecode {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #555555;
}

.tableTextStartTop {
  vertical-align: top;
}

.optionListNew svg {
  color: #333333;
  position: absolute;
  top: 0px;
  left: 14px;
}

.tableTextThreeLine {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}

.dateMinDiv {
  display: flex;
  flex-direction: column;
  position: relative;
}
.react-datepicker-wrapper {
  display: initial;
}
.react-datepicker-wrapper input {
  border: none;
  outline: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 10px;
  width: 100%;
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
}

.ql-editor {
  height: 45vh !important;
  overflow-x: auto !important;
}

.ql-toolbar.ql-snow {
  overflow: hidden;
}

.availability .tableMainSection .tableMainInner {
  border-radius: 0px;
  margin-bottom: 0px;
  overflow: hidden !important;
  box-shadow: 0px 0px 0px 0px #00000026;
  border: 0.3px solid #d3d3d3;
}

.tableMainInner .tableTdTextEmail {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #202020;
}

.jobsMainSection .react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: -20px;
  right: -15px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.jobsMainSection .react-datepicker__close-icon::after {
  height: 21px;
  width: 21px;
  padding: 2px;
  font-size: 17px;
}

.UserDetailDropMenu .ant-picker-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  padding: 9px 18px;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.playIcon {
  font-size: 4em;
  color: white;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .progressBar .MuiStepConnector-alternativeLabel {
    top: 30px;
    position: absolute;
    left: calc(-43% + 20px);
    right: calc(56% + 20px);
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    display: none;
  }

  .topSearchSectionInner {
    display: initial;
  }
  .topSearchMain {
    justify-content: flex-start;
  }
  .topSearchMain .form-control {
    width: 100%;
  }
  .driverDetaildCategory .nav-fill .nav-item .nav-link {
    font-size: 16px;
  }
  .bellIconMessageSection {
    display: none;
  }

  /* --new--- */
  .topSearchSectionInner h1 {
    font-size: 21px;
  }
  .userDetailsProfileInner {
    padding: 85px 0px 25px 0px;
  }
  .userDetailsProfileInner .userDetailsProfileImg {
    width: 125px;
    height: 125px;
  }
  .driverDetailsProfil .userDetailsProfileInner .userDetailsProfileImg {
    width: 125px;
    height: 125px;
  }
  .userDetailsProfile {
    padding: 75px 0px 0px 0px;
  }
  .userDetailsProfileInner h1 {
    font-size: 26px;
  }
  .userDetailsProfileInner p {
    font-size: 14px;
  }
  .userDetailsProfileInner h5 {
    font-size: 13px;
  }
  .userDetailMain .topSearchSectionInner h1 {
    font-size: 16px;
  }
  .userDetailsBackBtn span svg {
    width: 40px;
    height: 40px;
  }
  .userDetailsBackBtn h1 {
    font-size: 20px;
  }

  .categoryMinSection .metaDataTitle h1 {
    font-size: 16px;
  }
  .categoryMinSection .metadataTapLeft .nav-pills .nav-link span svg {
    font-size: 25px;
  }
  .categoryMinSection .nav-item .nav-link p {
    font-size: 14px;
  }
  .category_furnitureMainTop__Fef7Y h1 {
    font-size: 16px;
  }
  .metaDataTitle h1 {
    font-size: 18px;
  }

  .driverDetailsProfil .ProfileTopInnerRight h5 {
    font-size: 14px;
    gap: 7px;
  }
  .ProfileTopInnerRight ul li svg {
    height: 20px;
    width: 20px;
  }
  .ProfileTopInnerRight ul {
    gap: 1px;
  }

  .driverDetaildCategory .nav-pills .categorybtn {
    font-size: 15px !important;
    margin-bottom: 12px;
  }
  .UserDetailDropMenu .form-label {
    font-size: 13px;
  }
  .userDetailsProfileInner h1 {
    width: 50%;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: unset !important;
  }
}

.trackJobStepper .MuiStepConnector-horizontal {
  top: 25px !important;
}

@media only screen and (max-width: 400px) {
  .trackJobStepper .MuiStepLabel-iconContainer svg {
    width: 18px;
  }
  .trackJobStepper .MuiStepLabel-iconContainer img {
    width: 18px;
  }
  .trackJobStepper .MuiStepLabel-iconContainer div {
    height: 35px;
    width: 35px;
  }
  .trackJobStepper .MuiStepConnector-line {
    margin-top: -6px;
  }
  .trackJobStepper .MuiStepConnector-root {
    left: calc(-48% + 16px);
    right: calc(52% + 16px);
  }
}

@media (max-width: 888px) {
  .progressBar .MuiStepConnector-alternativeLabel {
    top: 25px !important;
  }
  .progressBar .MuiSvgIcon-fontSizeMedium {
    width: 22px;
    height: 22px;
  }
  .progressBar img {
    width: 22px;
    height: 22px;
  }

  .progressBar .css-1jy689i {
    width: 50px;
    height: 50px;
  }

  .progressBar .css-1hdr14z {
    color: #0f7dff;
    width: 50px;
    height: 50px;
  }
  .progressBar .css-19mloru {
    color: #0f7dff;
    width: 50px;
    height: 50px;
  }
  .progressBar .MuiStepConnector-alternativeLabel {
    top: 30px;
    position: absolute;
    left: calc(-45% + 20px);
    right: calc(55% + 20px);
  }
}

@media (max-width: 768px) {
  .userDetailsBackBtn h1 {
    font-size: 18px;
  }

  .userDetailsProfileInner h5 {
    font-size: 13px;
  }
  .userDetailsProfileInner h1 {
    font-size: 24px;
  }
  .userDetailsProfileInner p {
    font-size: 14px;
  }

  .userDetailsProfileInner .userDetailsProfileImg {
    width: 115px;
    height: 115px;
  }
  .driverDetailsProfil .userDetailsProfileInner .userDetailsProfileImg {
    width: 115px;
    height: 115px;
  }
  .driverDetailsImg img {
    width: 115px;
    height: 115px;
  }
  .userDetailsProfile {
    padding: 66px 0px 0px 0px;
  }
  .userDetailsProfileInner {
    padding: 65px 0px 25px 0px;
  }
  .UserDetailDropMenu .form-label {
    font-size: 12px;
  }
  .topSearchSectionInner h1 {
    font-size: 18px;
  }
  .metadataTapLeft .nav-pills .nav-link {
    font-size: 14px;
  }
  .userDetailsProfileInner h1 {
    width: 75%;
  }
}

@media (max-width: 775px) {
  .topSearchMain .form-control {
    width: 100%;
  }

  .driverDetaildTabBtn_Right {
    gap: 10px;
  }

  .userProfileTop {
    flex-direction: column;
  }

  .driverDetailsProfil .userDetailsProfileInner {
    padding: 70px 25px 25px 25px;
  }
  .ProfileTopInnerLeft {
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .ProfileTopInnerRight {
    align-items: center;
  }

  .footerSection .navbar-brand img {
    width: 140px !important;
    height: 55px;
  }
  .footerSection .nav-link {
    font-size: 13px;
  }
}

@media (min-width: 767px) and (max-width: 800px) {
  .driverDetDocumentTabInner h4 {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .driverDetCardImg {
    text-align: center;
  }
  .driverDetCardImg img {
    width: 100%;
    max-width: 300px;
  }

  .driverDetCardImgOverlay span {
    width: 100%;
    max-width: 300px;
  }

  .driverDetDocumentTab {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin: 0px auto;
  }

  .driverDetaildCategory .nav-pills .nav-link {
    padding: 10px 8px;
    font-size: 12px;
  }
}

@media (min-width: 756px) and (max-width: 880px) {
  .driverDetaildCategory .nav-pills .nav-link {
    padding: 10px 8px;
    font-size: 12px;
  }
}

@media (max-width: 800px) {
  .userDetailsTabsMain .nav.nav-tabs.nav-justified {
    flex-direction: column;
  }
  .userDetailsTabsMain .nav-tabs .nav-link {
    border-right: 0px solid #555555;
    text-align: left;
    padding: 8px;
  }
  .userDetailsTabsMain .nav-tabs .nav-link.active {
    background-color: #eae5e5;
    box-shadow: 0px 0px 1px 0px #000000;
  }

  .supportTabsMain .userDetailsTabsMain .nav.nav-tabs.nav-justified {
    flex-direction: column !important;
  }
  .supportTabsMain .userDetailsTabsMain .nav-tabs .nav-link {
    border-right: 0px solid #555555 !important;
    text-align: left !important;
    padding: 8px !important;
  }
  .supportTabsMain.userDetailsTabsMain .nav-tabs .nav-link.active {
    background-color: #eae5e5 !important;
    box-shadow: 0px 0px 1px 0px #000000 !important;
  }
  .supportTabsMain .userDetailsTabsMain .nav.nav-tabs.nav-justified {
    padding: none !important;
    border: 1px solid #cfcfcf !important;
    border-radius: 5px !important;
    background-color: #f7f7f7;
    height: 100%;
    display: flex;
  }
  button#justify-tab-example-tab-Driver_Support {
    border-right: 0px solid #555555 !important;
  }
}

@media (max-width: 575px) {
  .mmm .modal-dialog {
    margin: 17px 13px 17px 17px !important;
    width: 91% !important;
  }

  .userDetailsBtn {
    margin-top: 6px;
  }
}

@media (max-width: 425px) {
  .userDetailsProfileInner .userDetailsProfileImg {
    width: 95px;
    height: 95px;
  }
  .driverDetailsProfil .userDetailsProfileInner .userDetailsProfileImg {
    width: 95px;
    height: 95px;
  }
  .driverDetailsImg img {
    width: 95px;
    height: 95px;
  }

  .userDetailsProfile {
    padding: 56px 0px 0px 0px;
  }
  .userDetailsProfileInner {
    padding: 63px 0px 25px 0px;
  }

  .userDetailsProfileInner p {
    font-size: 12px;
  }
  .userDetailsProfileInner h5 {
    font-size: 13px;
  }
  .userDetailsBackBtn a svg {
    width: 44px;
    height: 44px;
  }

  .ProfileTopInnerRight ul li {
    width: 20px;
    margin-bottom: 2px;
  }

  .driverDetaildCategory .nav-fill .nav-item .nav-link {
    font-size: 12px;
  }

  .headersection nav {
    padding: 0px 20px;
  }

  .categoryMinSection .metadataTapLeft .nav-pills .nav-link span svg {
    width: 30px;
    height: 32px;
  }

  .driverDetailsProfil .userDetailsProfileInner {
    padding: 60px 10px 10px 10px;
  }
  .ProfileTopInnerLeft h1 {
    font-size: 16px;
  }

  .userDetailsProfileInner h1 {
    font-size: 20px;
  }
  .userDetailsProfileInner h1 {
    width: 90%;
  }

  /* resetbtn */
  .userDetailsBtn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    /* width: 100%; */
    /* height: 100%; */
    /* flex-wrap: wrap; */
  }
  .filterBtn {
    width: 100%;
    margin-bottom: 10px;
  }

  .resetBtn {
    width: 100%;
  }
}

@media only screen and (min-width: 361px) and (max-width: 450px) {
  .categoryMinSection .metadataTapLeft .nav-pills .nav-link img {
    margin-right: 5px;
    width: 32px;
    height: 32px;
  }
  .categoryMinSection .nav-item .nav-link p {
    font-size: 13px;
  }
}

@media (max-width: 375px) {
  .adminPageInner_heading {
    padding: 15px;
  }
  .dashboard_dashboard_card_inner {
    margin-bottom: 15px;
  }
  .headersection nav {
    padding: 0px 5px;
  }
  .jobDetailsInner {
    padding: 10px 15px;
  }
  .ProfileTopInnerLeft h1 {
    font-size: 12px;
  }
  .footerSection .nav-link {
    font-size: 12px;
  }

  /* -- */
  .userDetailsProfileInner .userDetailsProfileImg {
    width: 85px;
    height: 85px;
  }
  .driverDetailsProfil .userDetailsProfileInner .userDetailsProfileImg {
    width: 85px;
    height: 85px;
  }

  .driverDetailsProfil .userDetailsProfileInner {
    padding: 45px 10px 10px 10px;
  }
  .userDetailsBackBtn span svg {
    width: 35px;
    height: 35px;
  }
  .userDetailsBackBtn h1 {
    font-size: 16px;
  }
  .userDetailsProfileInner p {
    font-size: 11px;
  }
  .userDetailsProfileInner h5 {
    font-size: 12px;
  }
  .userDetailsProfileInner {
    padding: 45px 0px 25px 0px;
  }
  .userDetailMain .topSearchSectionInner h1 {
    font-size: 15px;
  }
  .driverDetailsProfil .ProfileTopInnerLeft h1 {
    font-size: 14px;
  }
  .driverDetailsProfil .ProfileTopInnerRight h5 {
    font-size: 13px;
  }
  .userDetailsProfileInner h1 {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .categoryMinSection .metadataTapLeft .nav-pills .nav-link img {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
  .categoryMinSection .nav-item .nav-link p {
    font-size: 10px;
  }
  .categoryMinSection .nav-item .nav-link {
    padding: 10px 5px;
  }
  .metadataTapLeft {
    padding: 15px 0px 0px 0px;
  }
}

@media (max-width: 350px) {
  .tablePaginationDash {
    padding: 10px 3px;
    margin-bottom: 20px;
  }

  .page-link svg {
    font-size: 25px;
  }
}

.tableTextStartTop {
  vertical-align: top;
}

.optionListNew svg {
  color: #333333;
  position: absolute;
  top: 0px;
  left: 14px;
}

.tableTextThreeLine {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}

/* .dropDownOnlyOneList {
  inset: auto 13px -35px auto !important;
} */

.dateMinDiv {
  display: flex;
  flex-direction: column;
  position: relative;
}
.react-datepicker-wrapper {
  display: initial;
}
.react-datepicker-wrapper input {
  border: none;
  outline: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 10px;
  width: 100%;
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
}

.backarrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.backarrow svg {
  box-shadow: 0px 0px 4px 0px #00000033;
  border-radius: 50%;
  font-size: 35px;
  padding: 8px;
  cursor: pointer;
}
.formOrderSection {
  margin: 8px 0px;
}

.formOrderSection .form-control {
  height: 50px;
  color: #584d4d;
}

.formOrderSection label {
  font-weight: 600;
  font-size: 22px;
  line-height: 26.63px;
  float: left;
}

.formOrderSection p {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  height: 50px;
  color: #584d4d;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  cursor: pointer;
}

.orderSection {
  position: relative;
}

.orderSectionLable {
  text-align: left;
  font-weight: 600;
  font-size: 22px;
  line-height: 26.63px;
  margin-bottom: 8px;
}

.orderSection svg {
  position: absolute;
  top: 50px;
  right: 13px;
  font-size: 25px;
  color: rgba(130, 130, 130, 0.85);
  cursor: pointer;
}
.liftSelection .form-check {
  display: flex;
  align-items: center;
  gap: 8px;
}
.liftSelection {
  margin-top: -14px;
}
.liftSelection .form-check-input {
  margin-top: 0px;
  padding: 8px;
  background-color: transparent !important;
  border-color: white !important;
  border-radius: 0% !important;
}
.stopsField {
  position: relative;
}
.stopsField svg {
  position: absolute;
  top: 29px;
  right: -3px;
  font-size: 25px;
  color: rgb(218 105 59);
  cursor: pointer;
}
.stopsField input {
  color: black !important;
  font-weight: 500;
}

.stopsField .form-control::placeholder {
  color: #584d4d !important;
  font-weight: 400;
}

.selectModal .floorCheckboxes {
  display: flex;
  justify-content: center;
  margin: 14px 0px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  background: #dcdcdc;
  color: black;
  height: 40px;
  line-height: 41px;
  border-radius: 4px;
}

.selectModal .categoryCheckboxes {
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: #555555;
}

.selectModal label {
  cursor: pointer;
}

.selectModal .modal-title {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}

.selectModal .modal-header svg {
  font-size: 30px;
  color: #0f7dff;
  cursor: pointer;
}

.selectModal .modal-body {
  background-color: #fafafa;
  border-radius: 8px;
}
.propertySection {
  position: relative;
  /* margin-top: 42px; */
}

.propertySection svg {
  position: absolute;
  top: 15px;
  right: 13px;
  font-size: 25px;
  color: rgba(130, 130, 130, 0.85);
  cursor: pointer;
}
.thirdTab {
  position: relative;
}
.thirdTab p {
  height: 50px !important;
}
.thirdTab .form-control {
  position: relative;
  height: 50px !important;
}
.thirdTab svg {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}

.form-control.is-invalid {
  background-image: none;
  padding-right: unset;
}

/* confirm alert modal css */
.confirmBox {
  background: #0f7dff;
  height: 70px;
}
.confirmIcon {
  text-align: center;
  border: 2px solid #0094f6;
  border-image-source: linear-gradient(180deg, #0094f6 0%, #2fc2fe 100%),
    linear-gradient(0deg, #ec3b1e, #ec3b1e);
  border-radius: 50%;
  width: 100%;
  max-width: 85px;
  height: 85px;
  margin: 0px auto;
  margin-top: -62px;
  background: #ffffff;
}

.confirmIcon svg {
  color: #ec3b1e;
  margin: 20px auto;
  font-size: 40px;
}

.confirmIconMsg {
  text-align: center;
  margin: 20px auto;
  width: 100%;
}

.confirmIconMsg h4 {
  color: red;
  font-size: 26px;
  font-weight: 600;
  line-height: 42.36px;
}

.confirmIconMsg p {
  color: #555555;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.41px;
  text-transform: capitalize;
}

.alertButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  gap: 12px;
}
.alertButton button.confirmLeftButton {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border: 1px solid #0f7dff70;
  background: #0f7dff;
  height: 40px;
  width: 100%;
  border-radius: 45px;
}
.alertButton button.alertRightButton {
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  height: 40px;
  width: 100%;
  border-radius: 45px;
  background: #ffffff;
  border: 3px solid #0094f6;
}

.fifthTab {
  position: relative;
}
.fifthTab svg {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}

.fifthTab p {
  background-color: #f9f9f9;
  height: 50px;
  margin-bottom: 0px;
}
.timePicker .MuiFormControl-root {
  width: 100%;
}
.timePicker .MuiFormControl-root .MuiSvgIcon-root {
  top: 0px;
}
.timePicker label {
  font-weight: unset;
  font-size: 15px;
  line-height: 26.63px;
  float: left;
}
.packing_switch .form-check-input {
  height: 20px;
  width: 40px;
}
.validateError {
  color: rgba(255, 0, 0, 0.877);
  font-size: 14px;
  margin-top: 4px;
}
/* @media only screen and (min-width: 992px) {
  .spaceTopManage {
    margin-top: 74px !important;
  }
}
.main {
  min-height: calc(100vh - 242px);
  overflow-x: hidden;
} */

/* phone input css */

.phoneInputSet .form-control {
  color: #7a7a7a !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
  height: 45px !important;
  box-shadow: 0px 3px 12px 0px #ffffff33 !important;
  border: 1px solid #bfbfbf !important;
  background: #ffffff !important;
  width: 100% !important;
}

.phoneInputSet .react-tel-input .flag-dropdown {
  border-right: none !important;
  background: #ffffff !important;
}
.form-control:focus {
  /* border: 0.5px solid #cdcdcd !important; */
  /* background-color: #f1f1f1 !important; */
}

.form-control:disabled {
  background-color: #f1f1f1;
}

.timePicker .MuiFormControl-root {
  width: 100%;
}
.timePicker .MuiFormControl-root .MuiSvgIcon-root {
  top: 0px;
}
.timePicker label {
  font-weight: unset;
  font-size: 15px;
  line-height: 26.63px;
  float: left;
}

.customTimePicker .MuiInputBase-input {
  width: 14%;
}
.customTimePicker .MuiInputBase-root {
  padding-right: unset;
}
.customTimePicker .MuiSvgIcon-root {
  display: none;
}
.customTimePicker .MuiInputAdornment-root {
  width: 100% !important;
  margin-left: -17%;
  margin-right: unset;
}
.customTimePicker .MuiButtonBase-root {
  width: 100% !important;
  height: 50px !important;
  border-radius: unset !important;
  margin-right: unset;
}

@media only screen and (max-width: 500px) {
  .customTimePicker .MuiInputBase-input {
    width: unset;
  }
}

.collectAddress {
  margin-top: 44px;
  position: relative;
}

.collectAddress svg {
  position: absolute;
  top: 12px;
  left: -38px;
}

.collectAddress input {
  color: black !important;
  font-weight: 500;
}

.collectAddress .form-control::placeholder {
  color: #584d4d !important;
  font-weight: 400;
}

.popover {
  position: absolute;
  /* inset: 0px auto auto 0px; */
  transform: none;
}

.popover-arrow {
  left: -7px;
}

.popover-body {
  position: absolute;
  background-color: white;
  left: -293px;
  top: 0;
  width: 330px;
  height: 270px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.pac-container {
  z-index: 9999 !important;
}

.valueBoldText {
  color: black;
  font-weight: 500;
}
