.couponHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.couponHeading h1 {
  font-family: Inter;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #333333;
}

.TutorialVideoRight {
  margin-bottom: 20px;
}

.TutorialVideoRight .videoRightBtnTop {
  display: flex;
  margin-bottom: 15px;
}

.videoRightBtnTop img {
  cursor: pointer;
  width: 26px;
  height: 26px;
}

.TutorialVideoRight .videoRightBtnTop p {
  margin-bottom: 0px;
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
}

.TutorialVideoRight .videoRightBtnBottom {
  display: flex;
  margin-bottom: 0px;
}
.TutorialVideoRight .videoRightBtnBottom p {
  margin-bottom: 0px;
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
}
.TutorialVideoRight .videoRightBtnBottom svg {
  width: 26px;
  height: 26px;
  fill: red;
}

.TutorialVideoRight .videoRightBtnBottom svg {
  cursor: pointer;
  width: 26px;
  height: 26px;
}

.videoAddbtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btnAddNew {
  background-color: #0f7dff;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 9px 30px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: end;
}

.TutorialVideoComponent {
  margin-bottom: 10px;
}

.TutorialVideoMain {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}
.descVideoTutorial {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  padding: 10px 0px 0px 0px;
}
.descVideoTutorialLeft {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  width: 80%;
  margin-right: 15px;
  word-break: break-all;
}
.descVideoTutorialRight {
  display: flex;
  padding-bottom: 10px;
}

.descVideoTutorialRight svg {
  width: 26px;
  height: 26px;
  fill: red;
}
.descVideoTutorialRight span svg {
  width: 26px;
  height: 26px;
  fill: #8e8e8e;
  cursor: pointer;
}

.TutorialVideoleft {
  position: relative;
  width: 313px;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-right: 15px;
}

.react-player video {
  border-radius: 9px;
}

@media (max-width: 991) {
  .couponHeading h1 {
    font-size: 21px;
  }
}

@media (max-width: 768) {
  .couponHeading h1 {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .TutorialVideoleft {
    margin-right: 0px;
  }
  .videoRightBtnTop img {
    width: 22px;
    height: 22px;
  }
  .TutorialVideoRight .videoRightBtnBottom svg {
    width: 22px;
    height: 22px;
  }
  .TutorialVideoRight .videoRightBtnTop p {
    font-size: 14px;
  }
  .TutorialVideoRight .videoRightBtnBottom p {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .TutorialVideoleft {
    position: relative;
    width: 313px;
    height: 180px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
  }
}
