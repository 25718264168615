.bellmessageBox {
  background-color: #ededed;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 12px 2px 12px;
  margin-bottom: 10px;
}
.bellmessageBox h5 {
  width: 40px;
  height: 40px;
  border: 0.2px solid #555555;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.bellmessageBox h5 img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}
.bellmessageBoxText {
  text-align: start;
}

.bellmessageBoxText span {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  text-align: start;
  color: #333333;
}

.bellmessageBoxText span b {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
  color: #202020;
  text-align: start;
}
.bellmessageBoxText p {
  font-family: Inter;
  font-size: 8px;
  font-weight: 400;
  color: #828282;
  margin-bottom: 0px;
  text-align: start;
  margin-bottom: 10px;
}

.headerMessageicon svg {
  width: 35px;
  height: 35px;
}

@media (max-width: 1024px) {
  .headerMessageicon svg {
    width: 32px;
    height: 32px;
  }
}
