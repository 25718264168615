.broadcastingMainLeft {
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 10px;
  height: 100vh;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.broadcastingMainLeft::-webkit-scrollbar {
  width: 4px;
  border-radius: 20px;
}

.broadcastingMainLeft::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 20px;
}

.broadcastingMainLeft::-webkit-scrollbar-thumb {
  background: #555555;
  border-radius: 20px;
}

.broadcastingMainLeftTop {
  padding: 20px 18px 10px 18px;
}

.broadcastingSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.broadcastingSelect h5 {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0px;
}

.broadcastingSelectLine {
  margin: 0px 0px 10px 0px;
  border: 0.5px solid #d9d9d9;
}

.broadLeftInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.broadLeftInner h3 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 3px;
  word-break: break-all;
}
.broadLeftInner p {
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  color: #626262;
  margin-bottom: 0px;
  word-break: break-all;
}
.broadLeftInner img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid #4770eb;
  margin-right: 15px;
  margin-bottom: 10px;
  object-fit: cover;
}

.broadcastingStarRating {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap;
}
.broadcastingStarRating span {
  display: flex;
  margin-bottom: 0px;
  margin-right: 10px;
  font-family: Proxima Nova;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}
.broadcastingStarRating span p {
  margin-bottom: 0px;
}
.broadcastingStarRating ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-bottom: 0px;
  padding: 0px;
}
.broadcastingStarRating ul li {
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.broadcastingStarRating ul li svg {
  width: 13px;
  height: 13px;
}

.broadLeftTitle {
  margin-bottom: 10px;
}

/* ---right--- */
.commissionMainSection {
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 6px;
  padding: 30px 25px;
  width: 100%;
}

.commissionMainSection h5 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 60px;
}

.commissionMainSection h5::after {
  content: "";
  display: block;
  width: 50px;
  margin-top: 4px;
  padding-top: 1px;
  border-radius: 20px;
  border-bottom: 2px solid transparent;
  background: #0f7dff;
}
.AddVanBtn button {
  width: 100%;
  padding: 10px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border: none;
}

@media (max-width: 991px) {
  .commissionMainSection {
    min-width: 100%;
  }
}

.broadCastUserType {
  margin-bottom: 18px;
}
.loadMore {
  text-align: center;
  margin-bottom: 8px;
}
.loadMore span {
  color: #0f7dff;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}
.broadcastHeading {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #cfcfcf !important;
  border-radius: 5px !important;
  height: 40px;
  background-color: #f7f7f7;
  align-items: center;
  margin: 0px 0px 32px 0px;
  font-weight: 600;
  color: #555555;
}

.headTitle {
  cursor: pointer;
}
