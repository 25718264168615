/* NotFound.css */

.notFoundWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: rgba(209, 200, 200, 0.8);
  }
  