.couponHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.couponHeading h1 {
  font-family: Inter;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #333333;
}

.vanTabAddVanButtion {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-bottom: 15px;
  padding: 10px 10px 10px 0px;
}
.vanTabAddVanButtion button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 27px;
  border: 0.5px solid #0f7dff54;
  border-radius: 4px;
  background-color: #0f7dff;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.AddVanBtn button {
  width: 100%;
  padding: 12px 0px;
  background: #0f7dff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
}
.ratingdiv {
  width: 100%;
  height: 52px;
  padding: 10px 5px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.ratingdiv svg {
  fill: #faaf00 !important;
}

.reviewMain svg {
  fill: #0094f6;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

@media (max-width: 991) {
  .couponHeading h1 {
    font-size: 21px;
  }
}

@media (max-width: 768) {
  .couponHeading h1 {
    font-size: 18px;
  }
}
