.topHead {
  display: flex;
  align-items: center;
  margin: 0px 80px;
}
.headTitle {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.searchInput {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.guestAddBtn {
  width: 100%;
  margin-top: 8px;
}
.driverImage {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.topDriverCard {
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 16px 8px;
}
.driverCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.driverAccept {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.driverAccept button {
  border-radius: 14px;
  background-color: #058321;
  padding: 4px 32px;
  font-size: 12px;
  font-weight: 600;
}
.loadMoreBtn {
  display: flex;
  justify-content: flex-end;
}
.loadMoreBtn button {
  padding: 8px 32px;
}
.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
}
.notFoundIcon img {
  width: 100px; /* Adjust the size of the icon */
  height: auto;
}
.notFoundMessage {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.customerName {
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  word-break: break-all;
}

.customerEmail {
  font-size: 12px;
  font-weight: 400;
  color: #626262;
  word-break: break-word;
  text-align: center;
}
