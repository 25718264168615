.topHead {
  display: flex;
  align-items: center;
  margin: 0px 80px;
}
.headTitle {
  font-size: 22px;
  font-weight: 500;
}
.topContainer {
  background-color: #f8f8f8;
}
.subContainer {
  margin: 0px 20px;
}
.topSubHeadDesc {
  display: flex;
  justify-content: center;
  padding: 32px;
}
.topDriverCard {
  background-color: white;
  border-radius: 4px;
  padding: 6px;
}
.driverCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.driverImage {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.driverImageDetail {
  border-radius: 100%;
  border: 4px solid #4770eb;
}
.driverRating img {
  margin-top: -8px;
}
.driverRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* justify-content: flex-end; */
  font-size: 14px;
  font-weight: 600;
  /* margin-bottom: -22px; */
  gap: 6px;
}
.driverReviewContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.driverBooked {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.driverReviewRating {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #d2e7ff;
  border-radius: 4px;
  padding: 2px 6px;
}
.driverReviewCount {
  margin-top: 4px;
  font-size: 10px;
  color: #626262;
}
.driverName {
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  word-break: break-all;
}
.driverTitle {
  font-size: 12px;
  font-weight: 400;
  color: #626262;
}
.driverPrice {
  font-size: 16px;
  font-weight: 600;
  margin: 0px 0px 8px 0px;
  color: #4770eb;
}
.driverPriceCancel {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: -8px;
  color: #6076b9;
}
.driverExtraHours {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #626262;
  margin-bottom: 8px;
}
.driverDetailPrice {
  font-size: 16px;
  font-weight: 700;
  margin: 8px 0px 10px 0px;
  color: #0f7dff;
}
.driverDetailPriceCancel {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: -8px;
  color: #5a93d7;
}
.driverAccept {
  display: flex;
  justify-content: center;
}
.driverAccept button {
  border-radius: 14px;
  background-color: #058321;
  padding: 4px 32px;
  font-size: 11px;
  font-weight: 600;
}
.nextBtnFooter {
  display: flex;
  justify-content: flex-end;
}
.chatFeature {
  cursor: pointer;
  margin: 0px 14px 12px 0px;
}
.detailsWrapper {
  background-color: #f8f8f8;
}

.driverBookedDetail {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
}

.driverDetailExtraHours {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #626262;
}

.driverJobBook {
  background-color: #d2e7ff;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 16px;
  font-weight: 600;
  width: min-content;
}

.driverJobBookText {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.ratingStarIcons {
  color: #0f7dff;
}

.driverReviewDetails {
  display: none;
}

.detailsContainer {
  padding: 90px 60px 20px 60px;
}
.profileSection {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ededed;
}
.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
  /* margin-right: -13%; */
}
.profileInfo img {
  height: 180px;
  width: 180px;
  /* border: 4px solid #4770eb; */
  /* border-radius: 100%; */
}
.profileRating {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.profileName {
  font-size: 30px;
  font-weight: 600;
  margin-top: 12px;
}
.profileEmail {
  font-size: 16px;
  font-weight: 400;
  color: #626262;
  word-break: break-word;
  text-align: center;
}
.profileNumber {
  font-size: 16px;
  font-weight: 500;
  color: #0f7dff;
  margin-top: 4px;
}
.ratingText {
  font-size: 16px;
  font-weight: 600;
  text-align: end;
}
.ratingsHeading {
  font-size: 24px;
  font-weight: 600;
  margin: 12px 0px;
}
.ratingsContainer {
  padding: 22px;
}
.mainReviewProfile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}
.reviewProfileTop {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.reviewProfile {
  display: flex;
  align-items: center;
  gap: 16px;
}
.reviewImage img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.reviewerName {
  font-size: 18px;
  font-weight: 700;
}
.reviewerEmail {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}
.reviewDate {
  font-size: 15px;
  font-weight: 400;
  color: #a3a3a3;
}
.reviewText {
  color: #333333;
  font-size: 15px;
  font-weight: 400;
}
.lineBar {
  display: flex;
  align-items: center;
}
.line {
  flex: 1;
}
.viewMoreBtn {
  font-size: 18px;
  font-weight: 600;
  padding: 12px;
  /* background-image: linear-gradient(to left, #0f7dff, #6931df); */
  background-clip: text;
  color: #6931df;
  cursor: pointer;
}
.acceptBtnFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
}
.acceptBtnFooter button {
  font-weight: 600;
  padding: 8px 80px;
  background-color: #058321;
}

@media screen and (max-width: 711px) {
  .ratingStarIcons {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 560px) {
  .topHead {
    margin: 0px 60px;
  }
  .profileInfo {
    margin-right: 0%;
  }
  .mainReviewProfile {
    gap: 8px;
  }
  .profileInfo img {
    height: 140px;
    width: 140px;
  }
  .profileName {
    font-size: 20px;
  }
  .driverBookedDetail {
    display: none;
  }
  .profileRating {
    display: none;
  }
  .driverReviewDetails {
    display: block;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;
  }
  .profileSection {
    flex-direction: column;
    justify-content: center;
  }
  .acceptBtnFooter {
    justify-content: center;
  }
}
@media screen and (max-width: 490px) {
  .topHead {
    margin: 0px 20px;
  }
  .detailsContainer {
    padding: 90px 20px 20px 20px;
  }
  .reviewStarRating span {
    font-size: 16px;
  }
  .profileSection {
    flex-direction: column;
  }
  .profileRating {
    margin-top: 12px;
  }
  .subContainer {
    margin: 0px 20px;
  }
  .reviewImage img {
    height: 40px;
    width: 40px;
  }
}

@media screen and (max-width: 400px) {
  .mainReviewProfile {
    flex-direction: column;
  }
}

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
}
.notFoundIcon img {
  width: 100px; /* Adjust the size of the icon */
  height: auto;
}
.notFoundMessage {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
  text-align: center;
}
.driverDiscountPrice {
}

.searchSection {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.searchSection button {
  border-radius: 16px;
}
.vanType {
  font-size: 16px;
  font-weight: 600;
  margin: 4px 0px;
}
