.userDetailsDate {
  height: 45px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  background-size: auto;
  border-radius: 4px;
  color: #757575;
  background-repeat: no-repeat;
  background-position: 90%;
  border: 0.8px solid #d0d0d0;
  z-index: 1;
}
.tableDate {
  color: #000000 !important;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  vertical-align: top;
}

.tableCategory {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #202020;
  vertical-align: top;
}

.tableAmount {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
  vertical-align: top;
}
.tableAmount span {
  display: flex;
  justify-content: start;
  align-items: center;
}
.tableAmount svg {
  fill: #202020;
  font-size: 14px;
  font-weight: 600;
}

.tableStatus {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  vertical-align: top;
}

/* ----jobDetails---- */
.statusText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.totalAmoutRight .inputGroupAmount {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}

.statusText button {
  background: #0094f6;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #ffffff;
  padding: 6px 24px;
  border-radius: 3px;
  border: 0.8px solid #0094f6;
  text-align: center;
  margin-bottom: 10px;
}

.statusText span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #555555;
  margin-bottom: 10px;
}

.statusText span p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0px;
  color: #df7411;
  text-transform: capitalize;
}

.furnitureMain {
  background-color: white;
  border: 1px solid #dadada;
  box-shadow: 0px 1px 4px 0px #00000014;
  border-radius: 8px;
  overflow: hidden;
}
.furnitureinnerTopText h1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  padding: 15px;
  margin-bottom: 0px;
}
.jobDetailsline {
  background: #333333;
  width: 100%;
  margin: 15px 0px 0px 0px;
  border: none;
  height: 1px !important;
  opacity: 0.7;
}
.paymentStatus {
  padding: 0px 15px 0px 15px;
}

.helpertabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 15px 5px 15px;
  border: 0.3px solid #e9e9e9;
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 5px;
  margin-bottom: 10px;
}
.helpertabs h1 {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 10px;
}
.helpertabs p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #0094f6;
  margin-bottom: 10px;
}

.helperRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 15px 5px 15px;
  border: 0.3px solid #e9e9e9;
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 5px;
  margin-bottom: 10px;
}

.helperRight h1 {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 10px;
}

.helperRight span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #0094f6;
  margin-bottom: 10px;
}
.helperRight span p {
  margin-bottom: 4px;
}
.helperRight div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.customerInfo {
  padding: 15px 15px 0px 15px;
  border: 0.3px solid #e9e9e9;
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
}
.customerInfo svg {
  position: absolute;
  top: 0px;
  right: 0px;
  fill: red;
  font-size: 20px;
}
.customerInfo h1 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
.customerInfoInner {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
}
.customerInfoInner span {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid #0f7dff80;
  margin-right: 15px;
  margin-bottom: 15px;
}
.customerInfoInner span img {
  width: 33px;
  height: 33px;
  margin-bottom: 15px;
  border-radius: 50%;
  object-fit: cover;
}
.customerText h1 {
  margin-bottom: 0px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.customerText p {
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  color: #626262;
  margin-bottom: 0px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.paymentAmount .helpertabs {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  flex-wrap: wrap;
  padding: 15px 15px 0px 15px;
  /* border: 0.3px solid #e9e9e9; */
  border-right: 0.3px solid #e9e9e9;
  border-left: 0.3px solid #e9e9e9;
  border-bottom: 0.1px solid #e9e9e9;
  border-top: 0.1px solid #e9e9e9;
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 0px;
  margin-bottom: 0px;
}
.paymentAmount .helpertabs h1 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
}
.paymentAmount .helpertabs p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #333333;
}

.statusTextMain .statusText span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 5px;
  /* margin-top: 20px; */
}
.statusTextMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.statusTextMain .statusText span p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #fd9700;
}
.releasePaymentBtn {
  padding: 25px 0px;
  width: 100%;
}

.releasePaymentBtn button {
  border: 2px solid #0f7dff;
  border-radius: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #0f7dff;
  padding: 10px 50px;
}

/* ----- */
.MapMainSection {
  border: 1px solid #dadada;
  box-shadow: 0px 1px 4px 0px #00000014;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 20px;
}

.MapMainSectionInner {
  padding: 15px;
}

.MapImg {
  border-radius: 8px;
  width: 100%;
  height: 230px;
  z-index: 99999;
}

.sourseLocation {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: #e4e4e4;
  padding: 15px 10px;
  margin-bottom: 15px;
}

.sourseLocation p {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #626262;
  margin-bottom: 5px;
}
.sourseLocation h1 {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  color: #202020;
  margin-bottom: 10px;
}
.floorDetails span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #626262;
  margin-bottom: 0px;
}
.floorDetails span p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #0f7dff;
  margin-right: 10px;
  border-right: 1.8px solid #555555;
  padding-right: 8px;
}

.liftAvailableLine {
  border-right: 1.8px solid transparent !important;
  color: #ff735c !important;
}
.stopsBootom {
  border-top: 1px solid #d9d9d9;
}

.stopsBootom h1 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  padding: 15px 15px 0px 15px;
}

.stopsBootom p {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #555555;
  margin-bottom: 0px;
}
.stopsBootomInner {
  padding: 15px;
}

.stopsBootomLine::after {
  content: "";
  display: block;
  width: 70px;
  margin-top: 4px;
  padding-top: 1px;
  border-bottom: 1px solid transparent;
  background-color: #0094f6;
}

.SelectVan {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.3px solid #e9e9e9;
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 5px;
  padding: 12px 15px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.SelectVanInner h1 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 10px;
}
.SelectVanInner p {
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  color: #4770eb;
  margin-bottom: 0px;
}
.SelectVan span {
  width: 90px;
  height: 60px;
}
.SelectVan span img {
  width: 90px;
  height: 60px;
}

/* ---locationProgressBar-- */
.pickupOrderStatus {
  background-color: white;
  border: 1px solid #dadada;
  box-shadow: 0px 1px 4px 0px #00000014;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 20px 10px 20px 10px;
  overflow: auto;
}

.pickupOrderStatus .statusInnerPickup h1 {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 0px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.statusInnerPickup p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  color: #626262;
  margin-bottom: 0px;
}

/* ------ */
.dote {
  text-align: start;
}
.dote ul {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 3px;
  padding: 0px;
  cursor: pointer;
  margin-bottom: 0px;
}
.dote ul li {
  list-style: none;
  width: 3.11px;
  height: 3.11px;
  border-radius: 100%;
  background-color: #333333;
}

.ratingSectionMain {
  background-color: white;
  margin-bottom: 40px;
  border: 0.3px solid #e9e9e9;
  padding: 12px 15px;
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 5px;
}
.ratingSectionMain p {
  word-wrap: break-word;
  font-size: 14px;
}

.ratingSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.ratingSection h5 {
  color: #3159d2;
  font-size: 17px;
  font-weight: 600;
  margin-top: 2px;
  margin-bottom: 0px;
}

.ratingSection p {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-top: 15px;
  line-height: 20px;
}
.paymentAmount .helpertabs svg {
  font-size: 16px;
  margin-bottom: 3px;
  fill: #333333;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
}

.paymentAmountSummary {
  padding: 15px;
}

/* ----driver--info */
.driverInfoMain {
  padding: 0px 15px 15px 15px;
}
.driverInfoInner {
  padding: 15px 15px 0px 15px;
  border: 0.3px solid #e9e9e9;
  box-shadow: 0px 0px 2px 0px #00000026;
}

.driverInfoMainInnerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
}
.driverInfoMainInnerTop p {
  margin-bottom: 10px;
}
.driverInfoMainInnerTop span {
  margin-bottom: 10px;
  color: #ec3b1e;
}

.currancySymbol {
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.15px;
  text-align: right;
  color: #333333;
}

.currancySymbolTable {
  font-family: Inter;
  font-weight: 600;
  color: #202020;
}

.ownItemTable {
  margin-bottom: 20px;
}

.driverInfoBootomMian span {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.driverInfoBootomMian span img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid #d6d6d680;
  object-fit: cover;
}

.driverInfoBootomMian span button {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  background: #0094f6;
  border-radius: 4px;
  padding: 8px 16px;
  border: none;
  color: white;
  margin-bottom: 15px;
}
.naClass {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #333333;
}

.currancyRed {
  color: #ec3b1e !important;
}

.currancyRed span {
  color: #ec3b1e !important;
}

.currancyRed svg {
  font-family: Inter;
  font-size: 17px !important;
  font-weight: 500;
  text-align: end;
  margin-bottom: 0px;
  margin-right: -3px;
  fill: #ec3b1e !important;
}

/* ------------assignDriver---- */
.topHead {
  display: flex;
  align-items: center;
}
.headTitle {
  font-size: 22px;
  font-weight: 500;
}
.topContainer {
  background-color: #f8f8f8;
}

.topSubHeadDesc {
  display: flex;
  justify-content: center;
  padding: 32px;
}
.topDriverCard {
  background-color: white;
  border-radius: 4px;
  padding: 16px 16px;
  box-shadow: 0px 0px 1px 0px #000000;
}
.driverCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.driverImage {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.driverImageDetail {
  border-radius: 100%;
  border: 4px solid #4770eb;
}
.driverRating img {
  margin-top: -8px;
}
.driverRating {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  gap: 6px;
}
.driverReviewContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.driverBooked {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.driverReviewRating {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #d2e7ff;
  border-radius: 4px;
  padding: 2px 6px;
}
.driverReviewRating svg {
  width: 15px;
  height: 15px;
  fill: #0094f6;
}
.driverReviewCount {
  margin-top: 4px;
  font-size: 10px;
  color: #626262;
}
.driverName {
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
}
.driverTitle {
  font-size: 12px;
  font-weight: 400;
  color: #626262;
  text-align: center;
}
.driverPrice {
  font-size: 16px;
  font-weight: 600;
  margin: 0px 0px 10px 0px;
  color: #4770eb;
}

.driverDiscount {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  color: #4770eb;
}
.driverDiscount span {
  text-decoration: line-through;
}
.driverExtraHours {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #626262;
  margin-bottom: 8px;
}
.driverDetailPrice {
  font-size: 16px;
  font-weight: 700;
  margin: 8px 0px 10px 0px;
  color: #0f7dff;
}
.driverAccept {
  display: flex;
  justify-content: center;
}
.driverAccept button {
  border-radius: 14px;
  background-color: #058321;
  font-size: 11px;
  font-weight: 600;
  border: none;
  color: white;
  width: 70%;
  height: 33px;
}
.nextBtnFooter {
  display: flex;
  justify-content: flex-end;
}
.chatFeature {
  cursor: pointer;
  margin: 0px 14px 12px 0px;
}
.detailsWrapper {
  background-color: #f8f8f8;
}

.driverBookedDetail {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
}

.driverDetailExtraHours {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #626262;
}

.driverJobBook {
  background-color: #d2e7ff;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 16px;
  font-weight: 600;
  width: min-content;
}

.driverJobBookText {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.ratingStarIcons {
  color: #0f7dff;
}

.driverReviewDetails {
  display: none;
}

.detailsContainer {
  padding: 90px 60px 20px 60px;
}
.profileSection {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ededed;
}
.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
}
.profileInfo img {
  height: 180px;
  width: 180px;
}
.profileRating {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.profileName {
  font-size: 30px;
  font-weight: 600;
  margin-top: 12px;
}
.profileEmail {
  font-size: 16px;
  font-weight: 400;
  color: #626262;
  word-break: break-word;
  text-align: center;
}
.profileNumber {
  font-size: 16px;
  font-weight: 500;
  color: #0f7dff;
  margin-top: 4px;
}
.ratingText {
  font-size: 16px;
  font-weight: 600;
  text-align: end;
}
.ratingsHeading {
  font-size: 24px;
  font-weight: 600;
  margin: 12px 0px;
}
.ratingsContainer {
  padding: 22px;
}
.mainReviewProfile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.reviewProfileTop {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.reviewProfile {
  display: flex;
  align-items: center;
  gap: 16px;
}
.reviewImage img {
  height: 60px;
  width: 60px;
}
.reviewerName {
  font-size: 18px;
  font-weight: 700;
}
.reviewerEmail {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}
.reviewDate {
  font-size: 15px;
  font-weight: 400;
  color: #a3a3a3;
}
.reviewText {
  color: #333333;
  font-size: 15px;
  font-weight: 400;
}
.lineBar {
  display: flex;
  align-items: center;
}
.line {
  flex: 1;
}
.viewMoreBtn {
  font-size: 18px;
  font-weight: 600;
  padding: 12px;
  background-clip: text;
  color: #6931df;
  cursor: pointer;
}
.acceptBtnFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
}
.acceptBtnFooter button {
  font-weight: 600;
  padding: 8px 80px;
  background-color: #058321;
}

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notFoundIcon img {
  width: 100px;
  height: auto;
}
.notFoundMessage {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.totalAmoutRight {
  text-align: end;
}

.formControlInput {
  display: block;
  width: 130px !important;
  border: 0.5px solid #cdcdcd;
  background-color: #f1f1f1;
  margin-bottom: 12px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  color: #333333;
}
.formControlInputGroupText {
  display: flex;
  padding: 9px 10px;
  border: 0.5px solid #cdcdcd;
  background-color: #f1f1f1;
  margin-bottom: 12px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  color: #333333;
}
.selectDateRange {
  display: grid;
}

.selectDateRange input {
  height: 45px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  background-size: auto;
  border-radius: 4px;
  color: #757575;
}

.totalAmoutRight > .formControlInput::placeholder {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 13.31px;
  text-align: center;
  color: #727272;
}

.totalAmoutRight p {
  text-align: right;
  margin-bottom: 15px;
}

.totalAmoutRight p button {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: right;
  cursor: pointer;
  border: none;
  background: #0094f6;
  border-radius: 4px;
  padding: 8px 16px;
  color: white;
}

.driverInfoListMain .customerInfo {
  border: none;
  box-shadow: none;
  margin-bottom: 0px;
}

.driverInfoList {
  border: 0.3px solid #e9e9e9;
  box-shadow: 0px 0px 2px 0px #00000026;
  padding: 12px 12px 0px 12px;
  margin-bottom: 12px;
  border-radius: 5px;
  position: relative;
  word-break: break-all;
}
.driverInfoList span {
  margin-bottom: 12px;
}
.driverInfoList p {
  margin-bottom: 12px;
}

.driverInfoList .customerInfoInner {
  overflow: initial;
  border: none;
  flex-wrap: initial;
}
.closeIconDriverInfo {
  cursor: pointer;
}

.driverInfoList svg {
  width: 21px;
  height: 20px;
  fill: #ec3b1e;
  position: absolute;
  top: -16%;
  right: -2px;
}

@media (max-width: 888px) {
  .pickupOrderStatus {
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .pickupOrderStatus::-webkit-scrollbar {
    width: 5px;
    height: 9px;
  }

  /* Track */
  .pickupOrderStatus::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }

  /* Handle */
  .pickupOrderStatus::-webkit-scrollbar-thumb {
    background: rgb(187, 186, 186);
    border-radius: 50px;
  }

  .pickupOrderStatus .statusInnerPickup h1 {
    font-size: 13px;
  }

  .progressBar .css-i4bv87-MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 711px) {
  .ratingStarIcons {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 560px) {
  .topHead {
    margin: 0px 60px;
  }
  .profileInfo {
    margin-right: 0%;
  }
  .mainReviewProfile {
    gap: 8px;
  }
  .profileInfo img {
    height: 140px;
    width: 140px;
  }
  .profileName {
    font-size: 20px;
  }
  .driverBookedDetail {
    display: none;
  }
  .profileRating {
    display: none;
  }
  .driverReviewDetails {
    display: block;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;
  }
  .profileSection {
    flex-direction: column;
    justify-content: center;
  }
  .acceptBtnFooter {
    justify-content: center;
  }
}

@media screen and (max-width: 490px) {
  .topHead {
    margin: 0px 20px;
  }
  .detailsContainer {
    padding: 90px 20px 20px 20px;
  }
  .reviewStarRating span {
    font-size: 16px;
  }
  .profileSection {
    flex-direction: column;
  }
  .profileRating {
    margin-top: 12px;
  }
  .reviewImage img {
    height: 40px;
    width: 40px;
  }
}

@media screen and (max-width: 400px) {
  .mainReviewProfile {
    flex-direction: column;
  }
}

@media (max-width: 375px) {
  .statusText span {
    font-size: 16px;
  }
  .furnitureinnerTopText h1 {
    font-size: 16px;
  }
  .helperRight span {
    text-align: start;
  }
  .statusTextMain .statusText span {
    font-size: 16px;
  }

  .statusTextMain .statusText span p {
    font-size: 16px;
  }

  .releasePaymentBtn button {
    padding: 10px 30px;
  }
}

@media (max-width: 330px) {
  .formControlInput {
    width: 100% !important;
  }
  .formControlInput {
    padding: 9px 9px;
  }
}
.pickUpImage {
  border-radius: 4px;
  height: 80px;
  width: 80px;
  cursor: pointer;
  object-fit: contain;
}
.pickUpTitle {
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
}
.cancelSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
}

.vanType {
  font-size: 16px;
  font-weight: 600;
  margin: 4px 0px;
}

.imageWrapper {
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 770px) {
  .pickUpImage {
    height: 50px;
    width: 50px;
  }
  .imageWrapper {
    overflow: scroll;
  }
}
.mileageAndTime {
  background-color: white;
  display: flex;
  border: 0.3px solid #e9e9e9;
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 5px;
  padding: 12px 15px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.timeTitle {
  font-size: 14px;
  font-weight: 500;
}
.timeValue {
  font-size: 14px;
  font-weight: 500;
  color: #0f7dff;
}
.driverTopSection {
  display: flex;
  align-items: center;
  gap: 22px;
}
.newDriverText {
  font-weight: 500;
}
