.formBlock {
    border-radius: 10px;
    max-width: 425px;
    margin: 0 auto;
    background: #FFFFFF;
    padding: 30px 20px;
    box-shadow: 0px 3px 12px 0px #FFFFFF33;
}

.logoImage {
    text-align: center;
    width: 100%;
}

.logoImage img {
    width: 100%;
    max-width: 237px;
}

.forgotText {
    text-align: center;
    margin-top: 15px;
}

.forgotText h3 {
    color: #000000;
    font-size: 26px;
    font-weight: 700;
    line-height: 49px;
}

.forgotText p {
    color: #6B6B6B;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    padding: 0px 20px;
    margin-bottom: 45px;
}

.buttonControls {
    display: flex;
    margin-top: 60px;
}

.leftButton {
    font-weight: 500;
    font-size: 16px;
    color: #575656;
    border-radius: 8px;
    background: #FFFFFF;
    border: unset;
    padding: 0px 10px;
    width: 100%;
    height: 48px;
    border: 1px solid #CDCDCD
}

.rightButton {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 21.78px;
    background: #0F7DFF;
    border-radius: 8px;
    border: unset;
    padding: 0px 10px;
    margin-left: 20px;
    width: 100%;
    height: 48px;
}
.loginMain {
    width: 100%;
    padding: 108.5px 0px;
    /* height: 100vh; */
    background-color: #0094f6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

